/* eslint-disable max-statements */
/* eslint-disable no-use-before-define */
import { FormikProps } from 'formik';
import { cloneDeep, uniqBy } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import DataTable, { IDataTableColumn } from 'react-data-table-component';

import { Declaration } from '@e-origin/shared';

import { StatusIcons } from '../../../../../assets';
import { Button, Spinner, TableActionButtons, TableActions } from '../../../../../components';
import { retrieveAdditionalCodes } from '../../../../../stores/batchesSlice';
import { IBeforeSendingFormData, ICommodityCodeRow } from '../before-sending-batch-form-initializer';
import { CommodityCodeModal } from './commodity-code-modal/commodity-code-modal';
import * as Style from './finalize-commodity-codes.style';

interface INationalCodesProps {
  declaration: Declaration;
  form: FormikProps<IBeforeSendingFormData>;
  disabled: boolean;
}

export const FinalizeCommodityCodes: React.FC<INationalCodesProps> = (props) => {
  const [rows, setRows] = useState<ICommodityCodeRow[]>([]);
  const [showModal, setShowModal] = useState(false);
  const selectedRow = useRef<ICommodityCodeRow>(null);
  const [loadingNationalCodes, setLoadingNationalCodes] = useState(false);

  const toggleShowEditItemModal = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    const tableData: ICommodityCodeRow[] = cloneDeep(props.form.values.commodityCodes);
    setRows(tableData);
  }, []);

  const handleSavingItem = (itemData: ICommodityCodeRow) => {
    const updatedRows = cloneDeep(rows).map((row) => {
      if (row.firstItemData.hsCode === itemData.firstItemData.hsCode) {
        return itemData;
      }
      return row;
    });
    setRows(updatedRows);
    props.form.setFieldValue('commodityCodes', updatedRows);
  };

  const populateDeclarationWithNationalCodes = () => {
    setLoadingNationalCodes(true);
    retrieveAdditionalCodes(props.declaration.messageInfo.batch).then((res) => {
      const updatedRows = rows.map<ICommodityCodeRow>((row) => {
        const data = res.find((item) => item.firstItemData.hsCode === row.firstItemData.hsCode);

        if (data) {
          return {
            riskAnalysis: data.riskAnalysis,
            firstItemData: {
              nationalAdditionalCode: data.firstItemData.nationalAdditionalCode,
              nationalAdditionalCode2: data.firstItemData.nationalAdditionalCode2,
              nationalAdditionalCode3: data.firstItemData.nationalAdditionalCode3,
              taricAdditionalCode: data.firstItemData.taricAdditionalCode,
              taricAdditionalCode2: data.firstItemData.taricAdditionalCode2,
              hsCode: data.firstItemData.hsCode,
            },
            additionalReference: uniqBy(
              [
                ...data.riskAnalysis.additionalReference.map((sd) => ({
                  selected: true,
                  document: {
                    type: sd.type,
                    referenceNumber: sd.referenceNumber,
                    dateOfValidity: sd.dateOfValidity,
                    header: false,
                  },
                })),
                ...data.newYCodes.map((code) => ({
                  selected: false,
                  document: {
                    type: code,
                    referenceNumber: '.',
                    dateOfValidity: undefined,
                    header: false,
                  },
                })),
              ],
              (sd) => `${sd.document.type}#${sd.document.referenceNumber}`,
            ),
          };
        }

        return row;
      });
      setRows(updatedRows);
      props.form.setFieldValue('commodityCodes', updatedRows);
      setLoadingNationalCodes(false);
    });
  };

  const handleTableAction = (type: string, row: ICommodityCodeRow) => {
    switch (type) {
      case 'view':
        selectedRow.current = row;
        toggleShowEditItemModal();
        break;
      default:
      // pass
    }
  };

  const columns = useRef<IDataTableColumn<ICommodityCodeRow>[]>([
    {
      name: 'Status',
      sortable: false,
      cell: (row) => {
        return (
          <Style.SvgIcon>
            <img
              src={row.riskAnalysis.hsCodeFound ? StatusIcons.GREEN_CIRCLE : StatusIcons.RED_WARNING}
              alt={'anomalies-detected-value'}
            />
          </Style.SvgIcon>
        );
      },
    },
    {
      name: 'HS Code',
      selector: (row) => row.firstItemData.hsCode,
      sortable: false,
    },
    {
      name: 'Taric Additional Code 1',
      selector: (row) => row.firstItemData.taricAdditionalCode,
    },
    {
      name: 'Taric Additional Code 2',
      selector: (row) => row.firstItemData.taricAdditionalCode2,
    },
    {
      name: 'National Additional Code 1',
      selector: (row) => row.firstItemData.nationalAdditionalCode,
      sortable: false,
    },
    {
      name: 'National Additional Code 2',
      selector: (row) => row.firstItemData.nationalAdditionalCode2,
      sortable: false,
    },
    {
      name: 'Actions',
      right: true,
      cell: (row: ICommodityCodeRow) => (
        <TableActionButtons
          actions={[TableActions.Edit]}
          noSpaceBetweenButtons
          onClick={(actionType) => {
            handleTableAction(actionType, row);
          }}
        />
      ),
    },
  ]);

  return (
    <Style.ItemsContainer>
      <Style.ItemsActionButton>
        {!props.disabled && (
          <Button outline onClick={populateDeclarationWithNationalCodes}>
            Get Codes
          </Button>
        )}
      </Style.ItemsActionButton>
      {!loadingNationalCodes ? (
        <DataTable noHeader columns={columns.current} data={rows} pagination paginationPerPage={5} />
      ) : (
        <Style.NoResultsWrapper>
          <Spinner />
        </Style.NoResultsWrapper>
      )}
      {showModal && (
        <CommodityCodeModal
          onHide={toggleShowEditItemModal}
          commodityCode={selectedRow.current}
          onSave={handleSavingItem}
          disabled={props.disabled}
        />
      )}
    </Style.ItemsContainer>
  );
};
