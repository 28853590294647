import moment from 'moment';

import { Discrepancy } from '@e-origin/shared';

import * as Style from './discrepancies.style';

interface IDiscrepanciesProps {
  discrepancies: Discrepancy[];
}

export const Discrepancies: React.FC<IDiscrepanciesProps> = ({ discrepancies }) => {
  return (
    <Style.Section topPadding={10} paddingBottom={20}>
      <Style.SectionTitle>Discrepancies</Style.SectionTitle>
      {discrepancies.map((item, idx) => (
        <Style.Row key={idx}>
          <div>
            <label>Notification date</label>
            <p>{moment(item.date).format('YYYY-MM-DD')}</p>
          </div>
          <div>
            <label>Notification type</label>
            <p>{item.text}</p>
          </div>
        </Style.Row>
      ))}
    </Style.Section>
  );
};
