/* eslint-disable max-classes-per-file */
import { prop, Ref } from '@typegoose/typegoose';

import { DeclarationDocumentsAdditionalFiscalReferenceRole, MethodsOfPayment } from '../enums';
import { BaseModel } from './base.model';
import { GuaranteeDocument } from './declaration';
import { DomainCollection } from './domain.collection';
import { User } from './user';

export class CustomerDeclarantAddress {
  @prop()
  street: string;

  @prop()
  postcode: string;

  @prop()
  city: string;

  @prop()
  country: string;
}

export class CustomerDeclarantContact {
  @prop()
  name: string;

  @prop()
  phoneNumber: string;

  @prop()
  email: string;
}

export class CustomerRepresentative {
  @prop()
  identificationNumber: string;

  @prop()
  identificationNumberIDMS: string;

  @prop()
  name: string;

  @prop()
  status: number;

  @prop({ _id: false, type: CustomerDeclarantAddress })
  address: CustomerDeclarantAddress;
}

export class CustomerDeclarant {
  @prop()
  _id: string;

  @prop()
  name: string;

  @prop()
  identificationNumber: string;

  @prop()
  identificationNumberIDMS: string;

  @prop({ default: false })
  useImporterEORI: boolean;

  @prop({ _id: false, type: CustomerDeclarantAddress })
  address: CustomerDeclarantAddress;

  @prop({ _id: false, type: CustomerDeclarantContact })
  contactPerson: CustomerDeclarantContact;

  @prop()
  authorisedIdentity?: string;
}

export class CustomerRepresentativeFiscalReference {
  @prop({ enum: DeclarationDocumentsAdditionalFiscalReferenceRole, type: String })
  role: DeclarationDocumentsAdditionalFiscalReferenceRole;

  @prop()
  vatIdentificationNumber: string;
}

export class CustomerPayments {
  @prop()
  methodOfPayment: string;

  @prop()
  methodOfPaymentVAT?: string;

  @prop()
  deferredPayment: string;

  @prop()
  deferredPaymentVAT: string;

  @prop({ _id: false, type: [CustomerRepresentativeFiscalReference] })
  additionalFiscalReference: CustomerRepresentativeFiscalReference[];
}

export class Range {
  @prop()
  from: number;

  @prop()
  to: number;
}

export class CustomerEvaluationAIHS {
  @prop({ _id: false, type: Range })
  range: Range;

  @prop()
  activateMethod: boolean;
}

export class CustomerEvaluationValue {
  @prop({ max: 1 })
  minimumRate: number;

  @prop()
  activateMethod: boolean;
}

export class CustomerEvaluationCalculator {
  @prop()
  marketplaceFees: number;

  @prop()
  fullfillmentCost: number;

  @prop()
  storageCost: number;

  @prop()
  otherCost: number;

  @prop()
  activateMethod: boolean;
}

export class CustomerEvaluationMethods {
  @prop({ _id: false, type: CustomerEvaluationAIHS })
  evalMethodAIHSCode: CustomerEvaluationAIHS;

  @prop({ _id: false, type: CustomerEvaluationCalculator })
  evalMethodCalculator: CustomerEvaluationCalculator;

  @prop({ _id: false, type: CustomerEvaluationValue })
  evalMethodWebValue: CustomerEvaluationValue;

  @prop({ _id: false, type: CustomerEvaluationValue })
  evalMethodIMADBStatisticalValue: CustomerEvaluationValue;
}

export class CustomerBatchHistoryStatus {
  @prop({ required: true })
  value: string;

  @prop()
  details?: string;
}

export class PersonPayingTheCustomDuty {
  @prop()
  identificationNumber: string;
}

export class PersonProvidingGuarantee {
  @prop()
  identificationNumber: string;
}

export class CustomerDocuments {
  @prop({ type: GuaranteeDocument, _id: false })
  guaranteeDocuments: GuaranteeDocument[];
}

class Authorisation {
  @prop()
  type?: string;

  @prop()
  referenceNumber?: string;
}

export class TransitGuaranteeReference {
  @prop()
  grn: string;

  @prop()
  accessCode: string;

  @prop()
  currencyCode: string;

  @prop()
  amountToBeCovered: string;
}

export class TransitGuarantee {
  @prop()
  type: string;

  @prop()
  reference: TransitGuaranteeReference;
}

@DomainCollection({ schemaOptions: { timestamps: true, strict: false } })
export class Customer extends BaseModel {
  @prop({ required: true })
  name: string;

  @prop({ type: CustomerDeclarant })
  declarant: CustomerDeclarant;

  @prop({ _id: false, type: CustomerRepresentative })
  representative: CustomerRepresentative;

  @prop({ _id: false, type: CustomerPayments })
  payments: CustomerPayments;

  @prop({ _id: false, type: CustomerEvaluationMethods })
  evaluationMethods: CustomerEvaluationMethods;

  @prop({ _id: false, type: PersonProvidingGuarantee })
  personProvidingGuarantee: PersonProvidingGuarantee;

  @prop({ _id: false, type: PersonPayingTheCustomDuty })
  personPayingTheCustomDuty: PersonPayingTheCustomDuty;

  @prop({ _id: false, type: CustomerDocuments })
  documents: CustomerDocuments;

  // todo: by default this is empty
  @prop({ ref: () => 'User', default: [] })
  externalUsers: Ref<User>[];

  @prop({ _id: false, type: [Authorisation] })
  transitAuthorisations?: Authorisation[];

  @prop({ _id: false, type: [TransitGuarantee] })
  transitGuarantees: TransitGuarantee[];
}

class AdditionalFiscalReference {
  @prop({ _id: false, type: DeclarationDocumentsAdditionalFiscalReferenceRole })
  role?: DeclarationDocumentsAdditionalFiscalReferenceRole;

  @prop()
  vatIdentificationNumber?: string;
}

export class Payments {
  methodOfPayment?: MethodsOfPayment;

  deferredPayment?: string;

  additionalFiscalReference: AdditionalFiscalReference[];
}

export class Representative {
  identificationNumber?: string;

  status?: string;
}
