/* eslint-disable no-use-before-define */
/* eslint-disable max-lines */
/* eslint-disable default-case */
/* eslint-disable max-statements */
import { useFormik } from 'formik';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

/* eslint-disable complexity */
import { GoodLocation } from '@e-origin/shared';

import { Autocomplete, Button, Dropdown, Input, Modal } from '../../../../components';
import { saveGoodsLocation, selectDeclarant } from '../../../../stores/declarantSlice';
import { retrieveCodes } from '../../../../stores/settingsSlice';
import { FormCheckbox, FormContainer, FormRow, FormSection, FormSectionTitle } from '../../../../styles/common';

interface IGoodLocationModalProps {
  show: boolean;
  onHide: () => void;
  goodLocationToEdit?: GoodLocation | null;
}

const validateRequiredFields = <T extends Record<string, any>>(
  values: T,
  requiredFields: (keyof T)[],
): Partial<Record<keyof T, string>> => {
  const errors: Partial<Record<keyof T, string>> = {};

  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = 'Required';
    }
  });

  return errors;
};

const GoodLocationModal: React.FC<IGoodLocationModalProps> = (props) => {
  const dispatch = useDispatch();

  const declarant = useSelector(selectDeclarant);

  const validate = (values: any) => {
    const errors: any = {};

    if (!values.type) {
      errors.type = 'Required';
    }
    if (!values.qualifierOfIdentification) {
      errors.qualifierOfIdentification = 'Required';
    }

    if (!values.customsOffice.referenceNumber) {
      errors.customsOffice = {
        referenceNumber: 'required',
      };
    }

    if (!values.unLocode) {
      errors.unLocode = 'required';
    }

    switch (values.qualifierOfIdentification) {
      case 'T': {
        const requiredFields = ['postcode', 'country', 'houseNumber'];
        const postcodeErrors = validateRequiredFields(values.postcode, requiredFields);
        if (Object.keys(postcodeErrors).length > 0) {
          errors.postcode = postcodeErrors;
        }
        break;
      }
      case 'W': {
        const requiredFields = ['latitude', 'longitude'];
        const gnssErrors = validateRequiredFields(values.gnss, requiredFields);

        if (Object.keys(gnssErrors).length > 0) {
          errors.gnss = gnssErrors;
        }
        break;
      }
      case 'X':
        if (!values.economicOperator.identificationNumber) {
          errors.economicOperator = {
            identificationNumber: 'Required',
          };
        }
        break;
      case 'Y':
        if (!values.authorisationNumber) {
          errors.authorisationNumber = 'Required';
        }
        break;
      case 'Z': {
        const requiredFields = ['street', 'postcode', 'country', 'city'];
        const addressErrors = validateRequiredFields(values.address, requiredFields);
        if (Object.keys(addressErrors).length > 0) {
          errors.address = addressErrors;
        }

        if (values.address.country && values.address.country.length > 2) {
          errors.address = {
            ...errors.address,
            country: 'Max 2 characters',
          };
        }
        break;
      }
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      _id: props.goodLocationToEdit?._id || '',
      defaultLocation: props.goodLocationToEdit?.defaultLocation || false,
      type: props.goodLocationToEdit?.type || '',
      qualifierOfIdentification: props.goodLocationToEdit?.qualifierOfIdentification || '',
      unLocode: props.goodLocationToEdit?.unLocode || '',
      customsOffice: {
        referenceNumber: props.goodLocationToEdit?.customsOffice?.referenceNumber || '',
      },
      gnss: {
        latitude: props.goodLocationToEdit?.gnss?.latitude || '',
        longitude: props.goodLocationToEdit?.gnss?.longitude || '',
      },
      economicOperator: {
        identificationNumber: props.goodLocationToEdit?.economicOperator?.identificationNumber || '',
      },
      authorisationNumber: props.goodLocationToEdit?.authorisationNumber || '',
      additionalIdentifier: props.goodLocationToEdit?.additionalIdentifier || '',
      address: {
        street: props.goodLocationToEdit?.address?.street || '',
        postcode: props.goodLocationToEdit?.address?.postcode || '',
        city: props.goodLocationToEdit?.address?.city || '',
        country: props.goodLocationToEdit?.address?.country || '',
      },
      postcode: {
        postcode: props.goodLocationToEdit?.postcode?.postcode || '',
        houseNumber: props.goodLocationToEdit?.postcode?.houseNumber || '',
        country: props.goodLocationToEdit?.postcode?.country || '',
      },
    } as GoodLocation,
    validate,
    onSubmit: async (values) => {
      // TODO: Add validation for fields and for business logic check the backend!!!
      dispatch(saveGoodsLocation(values));
      props.onHide();
    },
    enableReinitialize: false,
  });

  const startFormSubmit = () => {
    formik.submitForm();
  };

  const locationTypes = [
    { value: 'A', label: 'A - Designated location' },
    { value: 'B', label: 'B - Authorised place' },
    { value: 'C', label: 'C - Approved place' },
    { value: 'D', label: 'D - Other' },
  ];

  const qualifierTypes = [
    { value: 'T', label: 'T - Postcode address' },
    { value: 'U', label: `U - UN/LOCODE / Client's warehouse ID` },
    { value: 'V', label: 'V - Customs office identifier' },
    { value: 'W', label: 'W - GNSS coordinates' },
    { value: 'X', label: 'X - EORI number' },
    { value: 'Y', label: 'Y - Authorisation number' },
    { value: 'Z', label: 'Z - Address' },
  ];

  return (
    <Modal
      title={props.goodLocationToEdit ? 'Edit location of good' : 'New location of goods'}
      show={props.show}
      onHide={() => {
        props.onHide();
      }}
      buttons={[
        <Button type="button" primary key="save-modal-btn" onClick={startFormSubmit}>
          Save
        </Button>,
      ]}
      buttonsJustifyAlign="center"
    >
      <FormContainer onSubmit={formik.handleSubmit}>
        <FormSection verticalPadding={20} topPadding={40}>
          <FormRow>
            <FormCheckbox>
              <input
                name="defaultLocation"
                type="checkbox"
                checked={formik.values.defaultLocation}
                onChange={formik.handleChange}
              ></input>
              <span>Default location</span>
            </FormCheckbox>
          </FormRow>
          <FormRow>
            <Dropdown
              label="Type of location"
              placeholder="Select location type"
              options={locationTypes}
              onChange={(selectedOption) => {
                formik.setFieldValue('type', selectedOption?.value);
                formik.touched.type = true;
              }}
              value={locationTypes.find((item) => item.value === formik.values.type)}
              width={100}
              widthUnit="%"
              maxMenuHeight={500}
              invalid={formik.touched.type && formik.errors.type !== undefined && formik.errors.type.length > 0}
            />
          </FormRow>
          <FormRow>
            <Dropdown
              label="Qualifier of identification"
              placeholder="Select qualifier of identification"
              options={qualifierTypes}
              onChange={(selectedOption) => {
                formik.setFieldValue('qualifierOfIdentification', selectedOption?.value);
                formik.touched.qualifierOfIdentification = true;
              }}
              value={qualifierTypes.find((item) => item.value === formik.values.qualifierOfIdentification)}
              width={100}
              widthUnit="%"
              maxMenuHeight={500}
              invalid={
                formik.touched.qualifierOfIdentification &&
                formik.errors.qualifierOfIdentification !== undefined &&
                formik.errors.qualifierOfIdentification.length > 0
              }
            />
          </FormRow>
        </FormSection>
        <FormSection noTopPadding verticalPadding={20}>
          <FormSectionTitle>UNLOCODE/Warehouse ID</FormSectionTitle>
          <FormRow>
            <Input
              name="unLocode"
              placeholder="Unlocode"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.unLocode}
              width={100}
              widthUnit="%"
              invalid={
                formik.touched.unLocode && formik.errors.unLocode !== undefined && formik.errors.unLocode.length > 0
              }
            />
          </FormRow>
        </FormSection>
        <FormSection noTopPadding verticalPadding={20}>
          <FormSectionTitle>Customs office</FormSectionTitle>
          <FormRow>
            <Autocomplete
              width={100}
              widthUnit="%"
              placeholder="Reference number"
              fetchOptions={(search: string) => retrieveCodes('CL141', search, declarant.language)}
              onChange={(selectedOption) =>
                formik.setFieldValue('customsOffice.referenceNumber', selectedOption?.value)
              }
              value={{
                value: formik.values.customsOffice.referenceNumber,
                label: formik.values.customsOffice.referenceNumber,
              }}
              invalid={
                formik.touched.customsOffice?.referenceNumber &&
                formik.errors.customsOffice?.referenceNumber &&
                formik.errors.customsOffice?.referenceNumber.length > 0
              }
            />
          </FormRow>
        </FormSection>
        <FormSection noTopPadding verticalPadding={20}>
          <FormSectionTitle>Authorisation number</FormSectionTitle>
          <FormRow>
            <Input
              name="authorisationNumber"
              placeholder="Authorisation number"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.authorisationNumber}
              width={100}
              widthUnit="%"
            />
          </FormRow>
        </FormSection>
        <FormSection noTopPadding verticalPadding={20}>
          <FormSectionTitle>Additional identifier</FormSectionTitle>
          <FormRow>
            <Input
              name="additionalIdentifier"
              placeholder="Additional identifier"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.additionalIdentifier}
              width={100}
              widthUnit="%"
            />
          </FormRow>
        </FormSection>
        <FormSection noTopPadding verticalPadding={20}>
          <FormSectionTitle>GNSS</FormSectionTitle>
          <FormRow>
            <Input
              name="gnss.latitude"
              placeholder="Latitude"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.gnss.latitude}
              width={50}
              widthUnit="%"
              invalid={
                formik.touched.gnss?.latitude &&
                formik.errors.gnss?.latitude !== undefined &&
                formik.errors.gnss?.latitude.toString().length > 0
              }
            />
            <Input
              name="gnss.longitude"
              placeholder="Longitude"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.gnss.longitude}
              width={50}
              widthUnit="%"
              invalid={
                formik.touched.gnss?.longitude &&
                formik.errors.gnss?.longitude !== undefined &&
                formik.errors.gnss?.longitude.toString().length > 0
              }
            />
          </FormRow>
        </FormSection>

        <FormSection noTopPadding verticalPadding={20}>
          <FormSectionTitle>Economic operator</FormSectionTitle>
          <FormRow>
            <Input
              name="economicOperator.identificationNumber"
              placeholder="Identification number"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.economicOperator.identificationNumber}
              width={100}
              widthUnit="%"
              invalid={
                formik.touched.economicOperator?.identificationNumber &&
                formik.errors.economicOperator?.identificationNumber !== undefined &&
                formik.errors.economicOperator?.identificationNumber.toString().length > 0
              }
            />
          </FormRow>
        </FormSection>

        <FormSection noTopPadding verticalPadding={20}>
          <FormSectionTitle>Address</FormSectionTitle>
          <FormRow>
            <Input
              name="address.street"
              placeholder="Address"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.address.street}
              width={65}
              widthUnit="%"
              invalid={
                formik.touched.address?.street &&
                formik.errors.address?.street !== undefined &&
                formik.errors.address?.street.toString().length > 0
              }
            />
            <Input
              name="address.postcode"
              placeholder="Postcode"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.address.postcode}
              width={35}
              widthUnit="%"
              invalid={
                formik.touched.address?.postcode &&
                formik.errors.address?.postcode !== undefined &&
                formik.errors.address?.postcode.toString().length > 0
              }
            />
          </FormRow>
          <FormRow>
            <Input
              name="address.city"
              placeholder="City"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.address.city}
              width={50}
              widthUnit="%"
              invalid={
                formik.touched.address?.city &&
                formik.errors.address?.city !== undefined &&
                formik.errors.address?.city.toString().length > 0
              }
            />
            <Input
              name="address.country"
              placeholder="Country"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.address.country}
              width={50}
              widthUnit="%"
              invalid={
                formik.touched.address?.country &&
                formik.errors.address?.country !== undefined &&
                formik.errors.address?.country.toString().length > 0
              }
            />
          </FormRow>
        </FormSection>

        <FormSection noTopPadding verticalPadding={20}>
          <FormSectionTitle>Postcode</FormSectionTitle>
          <FormRow>
            <Input
              name="postcode.postcode"
              placeholder="Postcode"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.postcode.postcode}
              width={50}
              widthUnit="%"
              invalid={
                formik.touched.postcode?.postcode &&
                formik.errors.postcode?.postcode !== undefined &&
                formik.errors.postcode?.postcode.toString().length > 0
              }
            />
            <Input
              name="postcode.houseNumber"
              placeholder="House No."
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.postcode.houseNumber}
              width={50}
              widthUnit="%"
              invalid={
                formik.touched.postcode?.houseNumber &&
                formik.errors.postcode?.houseNumber !== undefined &&
                formik.errors.postcode?.houseNumber.toString().length > 0
              }
            />
            <Input
              name="postcode.country"
              placeholder="Country"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.postcode.country}
              width={50}
              widthUnit="%"
              invalid={
                formik.touched.postcode?.country &&
                formik.errors.postcode?.country !== undefined &&
                formik.errors.postcode?.country.toString().length > 0
              }
            />
          </FormRow>
        </FormSection>
      </FormContainer>
    </Modal>
  );
};

export default GoodLocationModal;
