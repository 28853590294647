import styled from 'styled-components';

export const ButtonContainer = styled.div`
  display: flex;
  align-items: flex-start;
  heigth: 100%;

  & > button {
    min-height: 100%;
  }
`;

export const Separator = styled.div<{ widthPercentage: number }>`
  border-bottom: 1px solid #ccc;
  margin: 16px 0;
  width: ${({ widthPercentage }) => `${widthPercentage}%`};
`;
