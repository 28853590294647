import styled from 'styled-components';

interface ContainerProps {
  width?: any;
  widthUnit?: any;
}
export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  width: 50%;
`;

export const Item = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 15px;
`;

export const DeleteButton = styled.button`
  margin-left: 10px;
  width: 50px;
`;

export const Separator = styled.div<{ widthPercentage: number }>`
  border-bottom: 1px solid #ccc;
  margin: 16px 0;
  width: ${({ widthPercentage }) => `${widthPercentage}%`};
`;
