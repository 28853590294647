/* eslint-disable complexity */
import {
  Batch,
  BatchStatusEnum,
  DeclarationCustomsFlowType,
  DeclarationStatus,
  EnvironmentCountries,
  getTotal,
  TemplateType,
} from '@e-origin/shared';
import { FC } from 'react';
import { Button, Modal } from '../../../components';
import { useConfig } from '../../../hooks/use-config';

interface IUpdateBatchModalProps {
  handleFirstButton(): void;
  handleSecondButton(): void;
  handleThirdButton(): void;
  onHide(): void;
  isHighValue: boolean;
  show: boolean;
  batch: Batch;
}

const BULK_UPDATE = 'Bulk Update';
const CHOOSE_AN_OPTION = 'Choose an option';
const LOAD_A_NEW_FILE = 'Load a new file';
export const INVALIDATE_BATCH = 'Invalidate batch';

const canInvalidateBatch = (batch: Batch, country: string): boolean => {
  const totalNotInvalidated = batch.statistics.total - (batch.statistics.invalidationPending || 0);

  if (country === EnvironmentCountries.BE && batch.templateType === TemplateType.LOW_VALUE_H7) {
    return batch.statistics.released > 0 && totalNotInvalidated > 0;
  }

  const released = getTotal(batch, DeclarationStatus.RELEASED, DeclarationCustomsFlowType.POST);

  if (country === EnvironmentCountries.NL) {
    const acceptedInPost = getTotal(batch, DeclarationStatus.ACCEPTED, DeclarationCustomsFlowType.POST);
    const acceptedInPre = getTotal(batch, DeclarationStatus.ACCEPTED, DeclarationCustomsFlowType.PRE);

    const totalAccepted = acceptedInPost + acceptedInPre;

    return (released > 0 || totalAccepted > 0) && totalNotInvalidated > 0;
  }

  return released > 0 && totalNotInvalidated > 0;
};

const canLoadNewFile = (batch: Batch, country: string, isHighValue: boolean): boolean => {
  if (country === EnvironmentCountries.BE || batch.status.value === BatchStatusEnum.FAILED) {
    return true;
  }

  if (isHighValue) {
    return batch.statistics.notSent > 0 || batch.statistics.analysis > 0 || batch.statistics.rejected > 0;
  }

  return (
    getTotal(batch, DeclarationStatus.NOT_SENT, DeclarationCustomsFlowType.PRE) > 0 ||
    getTotal(batch, DeclarationStatus.REJECTED, DeclarationCustomsFlowType.PRE) > 0
  );
};

const UpdateBatchModal: FC<IUpdateBatchModalProps> = (props) => {
  const { handleFirstButton, handleSecondButton, handleThirdButton, onHide, show, isHighValue, batch } = props;

  const { config } = useConfig();

  return (
    <Modal
      title={CHOOSE_AN_OPTION}
      show={show}
      onHide={onHide}
      buttons={[
        <Button
          type="button"
          primary
          key="load-a-new-file-batch-modal-btn"
          disabled={!canLoadNewFile(batch, config?.COUNTRY, isHighValue)}
          onClick={() => {
            handleFirstButton();
            onHide();
          }}
        >
          {LOAD_A_NEW_FILE}
        </Button>,
        <Button
          disabled={isHighValue}
          type="button"
          primary
          key="bul-update-batch-modal-btn"
          onClick={() => {
            handleSecondButton();
            onHide();
          }}
        >
          {BULK_UPDATE}
        </Button>,
        <Button
          disabled={isHighValue || !canInvalidateBatch(batch, config?.COUNTRY)}
          type="button"
          primary
          key="bul-invalidate-batch-modal-btn"
          onClick={() => {
            handleThirdButton();
            onHide();
          }}
        >
          {INVALIDATE_BATCH}
        </Button>,
      ]}
      buttonsJustifyAlign="center"
    />
  );
};

export default UpdateBatchModal;
