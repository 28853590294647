/* eslint-disable no-use-before-define */
// Async fetching function/* eslint-disable no-use-before-define */
/* eslint-disable no-nested-ternary */
/* eslint-disable complexity */
import debounce from 'lodash/debounce';
/* eslint-disable max-statements */
import { useEffect, useMemo, useRef, useState } from 'react';
import ReactDOM from 'react-dom';

import { Input } from '../';
import { Switcher } from '../switcher/switcher.component';
import * as Style from './autocomplete.style';

interface ISelect {
  label: string;
  value: string;
}

interface IAutocompleteProps {
  placeholder: string;
  /** used when not fetching asynchronously */
  options?: ISelect[]; // Used when not fetching asynchronously
  /** Async fetching function */
  fetchOptions?: (searchText: string) => Promise<ISelect[]>;
  onChange?(optionToSend: ISelect): void;
  onChangeNative?(event: React.ChangeEvent<HTMLInputElement>): void;
  onSelect?(optionToSend: ISelect): void;
  value?: ISelect;

  disabled?: boolean;
  compact?: boolean;
  width?: number;
  widthUnit?: 'px' | '%' | 'em' | 'rem';

  name?: string;

  // switcher props
  onHeaderChange?: (value: boolean) => void;
  headerValue?: boolean;
  headerDisabled?: boolean;
  invalid?: boolean;
}

export const Autocomplete: React.FC<IAutocompleteProps> = ({
  placeholder,
  options,
  name,
  onChange,
  onChangeNative,
  onSelect,
  value = { label: '', value: '' },
  fetchOptions,
  disabled,
  compact,
  width,
  widthUnit,
  onHeaderChange,
  headerDisabled,
  headerValue,
  invalid,
}) => {
  const inputRef = useRef<HTMLInputElement>(null); // Ref for the input element
  const [dropdownStyle, setDropdownStyle] = useState({}); // State to manage dropdown styles

  const [search, setSearch] = useState(value.value || '');
  const [searchLabel, setSearchLabel] = useState(value.label || '');

  const [filteredOptions, setFilteredOptions] = useState<ISelect[]>([]);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const isFirstRun = useRef(true);

  const debounceFetch = useMemo(
    () =>
      debounce(async (searchText: string) => {
        if (fetchOptions) {
          try {
            setLoading(true);
            const results = await fetchOptions(searchText);
            setFilteredOptions(results || []);
            setLoading(false);
          } catch (err) {
            setError('Failed to fetch options');
            setLoading(false);
          }
        } else {
          setFilteredOptions(filterOptions(searchText));
        }
      }, 300),
    [fetchOptions],
  );

  useEffect(() => {
    setFilteredOptions([]);
  }, [fetchOptions]);

  useEffect(() => {
    if (!isFirstRun.current) {
      debounceFetch(search);
    }
    isFirstRun.current = false;
  }, [search]);

  const filterOptions = (text: string): ISelect[] => {
    if (!text) return options;
    const lowerText = text.toLowerCase();
    return options.filter((option) => option.label.toLowerCase().startsWith(lowerText));
  };

  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    setSearchLabel(event.target.value);
    onChange?.({ label: event.target.value, value: event.target.value });
    onChangeNative?.(event);
    setOpenDropdown(true);
  };

  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setOpenDropdown(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const updateDropdownPosition = () => {
    if (inputRef.current) {
      const rect = inputRef.current.getBoundingClientRect();
      setDropdownStyle({
        position: 'absolute',
        top: `${rect.bottom + window.scrollY}px`,
        left: `${rect.left + window.scrollX}px`,
        width: `${rect.width}px`, // Optional: Match the width of the input
      });
    }
  };

  useEffect(() => {
    window.addEventListener('resize', updateDropdownPosition);
    window.addEventListener('scroll', updateDropdownPosition, true);

    return () => {
      window.removeEventListener('resize', updateDropdownPosition);
      window.removeEventListener('scroll', updateDropdownPosition, true);
    };
  }, []);

  useEffect(() => {
    updateDropdownPosition(); // Initial position update
  }, [inputRef.current]);

  const handleClick = () => {
    setOpenDropdown(true);
    if (!filteredOptions.length) {
      debounceFetch(search);
    }
  };

  const DropdownContainer = (
    <Style.AutocompleteDropdown ref={dropdownRef} style={dropdownStyle}>
      {loading ? (
        <div>Loading...</div>
      ) : error ? (
        <div>{error}</div>
      ) : (
        !!filteredOptions.length &&
        filteredOptions.map((option, optionIdx) => (
          <Style.AutompleteDropdownElement
            key={optionIdx}
            onClick={(event) => {
              event.stopPropagation();
              if (onSelect) {
                setSearch('');
                setSearchLabel('');
                onSelect(option);
              } else {
                setSearch(option.value);
                setSearchLabel(option.label);
              }
              onChange?.(option);

              onChangeNative?.({ target: { name, value: option.value } } as React.ChangeEvent<HTMLInputElement>);
              // eslint-disable-next-line no-unused-expressions
              setOpenDropdown(false);
            }}
            onBlur={() => setOpenDropdown(false)}
          >
            {option.label}
          </Style.AutompleteDropdownElement>
        ))
      )}
    </Style.AutocompleteDropdown>
  );
  return (
    <Style.AutocompleteContainer width={width || 100} widthUnit={widthUnit} ref={inputRef}>
      {onHeaderChange && (
        <Style.SwitcherWrapper>
          <Switcher size="sm" active={headerValue} onChange={onHeaderChange} disabled={headerDisabled} label="Header" />
        </Style.SwitcherWrapper>
      )}
      <Input
        defaultBehaviour
        name={name}
        placeholder={placeholder || ''}
        onChange={handleValueChange}
        onFocus={handleClick}
        value={searchLabel}
        width={100}
        widthUnit="%"
        disabled={!!disabled}
        compact={!!compact}
        invalid={invalid}
      />
      {openDropdown && ReactDOM.createPortal(DropdownContainer, document.body)}
    </Style.AutocompleteContainer>
  );
};
