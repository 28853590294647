import styled from 'styled-components';
import tw from 'twin.macro';

export const Container = styled.div`
  ${tw`
    flex
    flex-col
    flex-shrink-0
    mt-8
  `}
`;

export const ActionButton = styled.div`
  ${tw`
    flex
    py-3
    justify-end
  `}

  &> * {
    margin-left: 10px;
  }
`;

export const TableWrapper = styled.div`
  margin-bottom: 20px;
`;
