/* eslint-disable max-statements */
import moment from 'moment';
import { useState } from 'react';

import { Button, DatePicker } from '../../../../components';
import { PageTitle } from '../../../../styles/common';
import * as Style from './batches-archived-report.style';

interface VatReportProps {
  downloadReport: (date: Date) => Promise<void>;
  title: string;
}

export const BatchesArchivedReport: React.FC<VatReportProps> = ({ title, downloadReport }) => {
  const [date, setDate] = useState(moment().subtract(2, 'months').toDate());

  const [isLoading, setIsLoading] = useState(false);

  const handleDownloadReport = async () => {
    setIsLoading(true);
    await downloadReport(date);
    setIsLoading(false);
  };

  return (
    <div>
      <PageTitle>{title}</PageTitle>

      <Style.FieldContainer>
        <DatePicker
          placeholder="Unarchive from date (older than 2 months)"
          value={date}
          onChange={(value: Date) => setDate(value)}
          pickerProps={{ maxDate: moment().subtract(2, 'months').toDate() }}
        />
      </Style.FieldContainer>

      <Style.DownloadButton>
        <Button showLoading={isLoading} primary onClick={handleDownloadReport} disabled={isLoading}>
          {isLoading ? 'Generating report..' : 'Download report'}
        </Button>
      </Style.DownloadButton>
    </div>
  );
};
