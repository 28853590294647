import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
`;

export const Chart = styled.div`
  width: 60%;
  height: 100%;
`;

export const Actions = styled.div`
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 24px;
`;
export const DatePickerWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const DatePickerItem = styled.div`
  display: flex;
  flex-direction: row;

  & .react-datepicker-wrapper {
    & input {
      width: 150px;
      margin: 0 0 16px 16px;
    }
  }
`;

export const TextWithIcon = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  width: fit-content;

  & label {
    margin: 0 0 16px 16px;
    cursor: pointer;
  }
`;

export const TableContainer = styled.div`
  width: 500px;
  height: 480px;
  overflow: auto;
  box-shadow:
    2px 2px 12px #88888847,
    -2px 7px 12px #88888847;
`;

export const Table = styled.table`
  border: 1px solid #0000004f;
  border-collapse: collapse;
`;

export const Header = styled.tr`
  border: 1px solid #0000004f;
  border-collapse: collapse;
`;

export const HeaderColumn = styled.th`
  border: 1px solid #0000004f;
  border-collapse: collapse;
  min-width: 200px;
  max-width: 300px;
`;

export const Line = styled.tr`
  border: 1px solid #0000004f;
  border-collapse: collapse;
`;

export const LineColumn = styled.th`
  border: 1px solid #0000004f;
  border-collapse: collapse;
  min-width: 200px;
  max-width: 300px;
`;
