/* eslint-disable max-statements */
import { Transit } from '@e-origin/shared';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CustomTable } from '../../../components';
import {
  fetchTransits,
  nextTransits,
  selectTransitFilters,
  selectTransitPagination,
  selectTransits,
  selectTransitSorting,
  selectTransitTotaItems,
  setTransitSorting,
} from '../../../stores/transitsSlice';

import { TransitModal, TransitModalEnum } from '../modal/transit-modal.component';
import { useTransitsTableColumns } from './transits-table-columns';

export const TransitsTable: React.FC = () => {
  const dispatch = useDispatch();
  const transitList = useSelector(selectTransits);
  const totalTransits = useSelector(selectTransitTotaItems);

  const filters = useSelector(selectTransitFilters);
  const sorting = useSelector(selectTransitSorting);
  const pagination = useSelector(selectTransitPagination);

  const criteriaInitialized = useRef<boolean>(false);
  const firstQuery = useRef<boolean>(true);

  useEffect(() => {
    if (!criteriaInitialized.current) {
      return;
    }
    dispatch(fetchTransits({ persistPagination: firstQuery.current }));
    firstQuery.current = false;
  }, [sorting, pagination.size, filters]);

  useEffect(() => {
    dispatch(fetchTransits());
    criteriaInitialized.current = true;
  }, []);

  const [showTransitModal, setShowTransitModal] = useState(false);
  const selectedModal = useRef<TransitModalEnum>(undefined);
  const selectedRow = useRef<Transit>(null);

  const openModal = (row: Transit, modal: TransitModalEnum) => {
    selectedModal.current = modal;
    selectedRow.current = row;
    setShowTransitModal(true);
  };

  const fetchData = () => {
    dispatch(fetchTransits());
  };

  const columns = useTransitsTableColumns({ openModal });

  const handleSorting = (updatedSorting) => {
    dispatch(setTransitSorting(updatedSorting));
  };

  const handlePagination = (updatedPagination) => {
    dispatch(nextTransits(updatedPagination));
  };

  return (
    <>
      <TransitModal
        show={showTransitModal}
        selectedData={selectedRow.current}
        modal={selectedModal.current}
        onHide={() => setShowTransitModal(false)}
        onFinish={fetchData}
      />

      <CustomTable
        columns={columns.current}
        pagination={pagination}
        sorting={sorting}
        onSortingChange={handleSorting}
        onPaginationChange={handlePagination}
        data={transitList || []}
        total={totalTransits}
        isLoading={false}
        disablePagination={false}
      />
    </>
  );
};
