/* eslint-disable consistent-return */
import styled, { css } from 'styled-components';
import tw from 'twin.macro';

interface IPageContainerProps {
  isRelative?: boolean;
  overflowY?: boolean;
}

export const Title = styled.div<IPageContainerProps>`
  ${tw`
    flex
    flex-col
    flex-1
    h-full
  `};
  position: ${({ isRelative }) => (isRelative ? 'relative' : 'unset')};
  overflow-y: ${({ overflowY }) => (overflowY ? 'auto' : 'unset')};
`;

interface ISectionProps {
  noTopPadding?: boolean;
  verticalPadding?: number;
  topPadding?: number;
  paddingBottom?: number;
}

export const Section = styled.div<ISectionProps>`
  ${tw`
    flex
    flex-col
  `};
  padding: ${({ noTopPadding, verticalPadding }) =>
    noTopPadding
      ? `0 0 ${verticalPadding !== undefined ? verticalPadding : 40}px 0`
      : `${verticalPadding !== undefined ? verticalPadding : 40}px 0`};

  ${({ topPadding }) => {
    if (topPadding) {
      return css`
        padding-top: ${topPadding}px;
      `;
    }
  }}

  ${({ paddingBottom }) => {
    if (paddingBottom !== undefined) {
      return css`
        padding-bottom: ${paddingBottom}px;
      `;
    }
  }}
`;

interface ISectionTitleProps {
  noTopBorder?: boolean;
  spaceBetween?: boolean;
}

export const SectionTitle = styled.div<ISectionTitleProps>`
  ${tw`
    font-extrabold
  `}
  border-top: ${({ noTopBorder }) => (noTopBorder ? 'none' : '1px solid #dedede')};
  padding: ${({ noTopBorder }) => (noTopBorder ? '10px 0 10px 0' : '25px 0 10px 0')};
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.36px;
  justify-content: ${({ spaceBetween }) => (spaceBetween ? 'space-between' : 'unset')};
`;

export const Row = styled.div`
  ${tw`flex`};

  margin-bottom: 10px;

  & > div {
    margin-right: 50px;

    &:last-of-type {
      margin-right: 0;
    }
  }
`;

export const SubSection = styled.div`
  margin-top: 15px;
  margin-left: 20px;
`;

export const SubSectionTitle = styled.div`
  ${tw`
    font-bold
  `}
  font-size: 16px;
  margin-bottom: 10px;
  opacity: 0.75;
`;
