/* eslint-disable complexity */
import { FormikProps } from 'formik';

import { MultilingualCodeSystems } from '@e-origin/shared';

import trashIcon from '../../../../assets/icons/trash-icon.svg';
import { Autocomplete, Button, Input, MultiLangCodes } from '../../../../components';
import { FormRow } from '../../../../styles/common';
import * as Style from './transit-duplicate-row.style';
import { useEffect } from 'react';

interface InputConfig {
  type: 'Autocomplete' | 'MultiLang' | 'Input';
  name: string;
  code?: string;
  label: string;
  width?: number;
  search?: (searchText: string) => Promise<{ value: string; label: string }[]>;
}

interface TransitDuplicateRowProps {
  form: FormikProps<any>;
  inputs: InputConfig[];
  compact?: boolean;
  arrayPath: string;
  addButtonLabel: string;
  inputsPerRow?: number;
  disabled?: boolean;
  maxRows?: number;
}

export const TransitDuplicateRow: React.FC<TransitDuplicateRowProps> = ({
  form,
  inputs,
  arrayPath,
  addButtonLabel,
  compact,
  inputsPerRow = 3,
  disabled = false,
  maxRows = 9999,
}) => {
  const addRow = () => {
    const rows = [...form.getFieldProps(arrayPath).value];
    form.setFieldValue(arrayPath, [...rows, {}]);
  };

  const removeRow = (rowIdx: number) => {
    const rows = [...form.getFieldProps(arrayPath).value];
    rows.splice(rowIdx, 1);
    form.setFieldValue(arrayPath, rows);
  };

  const chunkInputs = (inputList: InputConfig[], chunkSize: number) => {
    const chunks = [];
    for (let i = 0; i < inputList.length; i += chunkSize) {
      chunks.push(inputList.slice(i, i + chunkSize));
    }
    return chunks;
  };

  const renderInputField = (inputConfig: InputConfig, rowIdx: number, inputConfigIdx: number) => {
    const inputName = `${arrayPath}[${rowIdx}].${inputConfig.name}`;

    const commonInputAttributes = {
      key: inputConfigIdx,
      placeholder: `${inputConfig.label} ${rowIdx + 1}`,
      width: inputConfig.width || 100,
      widthUnit: '%' as any,
      compact: !!compact,
      disabled,
    };

    switch (inputConfig.type) {
      case 'MultiLang':
        return (
          <MultiLangCodes
            system={MultilingualCodeSystems.NCTS}
            code={inputConfig.code}
            name={inputName}
            onChange={form.handleChange}
            value={form.getFieldProps(inputName)?.value || ''}
            {...commonInputAttributes}
          />
        );
      case 'Autocomplete':
        return (
          <Autocomplete
            fetchOptions={inputConfig.search}
            onChange={(selectedOption) => form.setFieldValue(inputName, selectedOption.value)}
            value={{
              value: form.getFieldProps(inputName)?.value || '',
              label: form.getFieldProps(inputName)?.value || '',
            }}
            {...commonInputAttributes}
          />
        );
      default:
        return (
          <Input
            onChange={(evt) => form.setFieldValue(inputName, evt.target.value)}
            name={inputName}
            defaultBehaviour
            value={form.getFieldProps(inputName)?.value || ''}
            {...commonInputAttributes}
          />
        );
    }
  };

  const renderInputs = (inputList: InputConfig[], rowIdx: number) => {
    const inputChunks = chunkInputs(inputList, inputsPerRow);

    return (
      <>
        {inputChunks.map((chunk, chunkIdx) => (
          <FormRow key={chunkIdx}>
            {chunk.map((inputConfig, inputConfigIdx) => renderInputField(inputConfig, rowIdx, inputConfigIdx))}
            {chunkIdx === 0 && !disabled && (
              <Style.DeleteButton type="button" onClick={() => removeRow(rowIdx)}>
                <img src={trashIcon} alt="Remove" />
              </Style.DeleteButton>
            )}
          </FormRow>
        ))}
      </>
    );
  };

  const arrayPathValue = form.getFieldProps(arrayPath).value || [];

  useEffect(() => {
    if (!arrayPathValue.length) {
      addRow();
    }
  }, [form.values]);

  return (
    <>
      {arrayPathValue.map((_, rowIdx) => (
        <div key={`${arrayPathValue.length}-${rowIdx}`}>
          {renderInputs(inputs, rowIdx)}
          {rowIdx < arrayPathValue.length - 1 && <Style.Separator widthPercentage={inputs.length === 2 ? 49 : 74} />}
        </div>
      ))}
      {!disabled && maxRows > arrayPathValue.length && (
        <Style.Item>
          <Button type="button" outline onClick={addRow}>
            {addButtonLabel}
          </Button>
        </Style.Item>
      )}
    </>
  );
};
