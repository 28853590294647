/* eslint-disable no-multi-assign */
/* eslint-disable max-lines */
/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-empty-function */
/* eslint-disable no-multi-assign */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable max-lines */
/* eslint-disable complexity */
/* eslint-disable max-statements */
import { FormikConfig, FormikProps, useFormik } from 'formik';
import { FC, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { Customer, EnvironmentCountries } from '@e-origin/shared';

import backIcon from '../../assets/icons/back-icon.svg';
import { Input, Tab, Tabs } from '../../components';
import { CustomerDetailsTabs, EditCustomerType } from '../../enums';
import { useConfig } from '../../hooks/use-config';
import {
  createNewCustomer,
  editCustomerDetails,
  fetchCustomerDetails,
  selectCustomerDetails,
} from '../../stores/customersSlice';
import { selectDeclarant } from '../../stores/declarantSlice';
import { PageContainer } from '../../styles/common';
import CustomerDetailsApiUser from './customer-details-api-user/customer-details-api-user.component';
import CustomerDetailsDeclarant from './customer-details-declarant/customer-details-declarant.component';
import { CustomerDetailsFormContainer } from './customer-details-declarant/customer-details-declarant.styles';
import CustomerDetailsDocuments from './customer-details-documents/customer-details-documents.component';
import { customerDetailsFormValuesBuilder } from './customer-details-form-values.builder';
import CustomerDetailsPayments from './customer-details-payments/customer-details-payments.component';
import CustomerDetailsRepresentative from './customer-details-representative/customer-details-representative.component';
import CustomerDetailsTransitAuth from './customer-details-transit-auth/customer-details-transit-auth.component';
import {
  CustomerDetailsBackButton,
  CustomerDetailsPageHeader,
  CustomerDetailsPageTitle,
} from './customer-details.styles';
import { EvaluationMethods } from './evaluation-methods/evaluation-methods.component';
import { CustomerDetailsGuaranteesTransit } from './customer-details-guarantees-transit/customer-details-guarantees-transit.component';

export type CustomerDetailsParamsType = {
  customerId: string;
};

export type UseFormikType = FormikProps<Customer>;

export type CustomerDetailsTabType = {
  handleSubmitCustomer(type: EditCustomerType): void;
  isButtonDisabled: boolean;
};

const getDeclarantTabLabel = (country: EnvironmentCountries): string => {
  if (country === EnvironmentCountries.NL) return 'Representative (HV)';

  return 'Declarant (HV)';
};

const CustomerDetails: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { config } = useConfig();

  const { customerId } = useParams<CustomerDetailsParamsType>();

  const isCreate = customerId === 'add';

  const customerDetails = useSelector(selectCustomerDetails);
  const generalDeclarant = useSelector(selectDeclarant);

  const { declarant: customerDetailsDeclarant } = customerDetails;

  const resettedTabsValidityValues = () => ({
    [CustomerDetailsTabs.DECLARANT]: true,
    [CustomerDetailsTabs.EVALUATION_METHODS]: true,
    [CustomerDetailsTabs.PAYMENTS]: true,
    [CustomerDetailsTabs.REPRESENTATIVE]: true,
  });
  const tabsValidity = useRef(resettedTabsValidityValues());

  const validate: FormikConfig<Customer>['validate'] = (values) => {
    tabsValidity.current = resettedTabsValidityValues();

    const errors: Partial<Record<keyof Customer, any>> = {
      evaluationMethods: {
        evalMethodCalculator: {
          globalError: '',
          marketplaceFees: '',
          fullfillmentCost: '',
          storageCost: '',
          otherCost: '',
          activateMethod: '',
        },
        evalMethodWebValue: {
          globalError: '',
          minimumRate: '',
        },
        evalMethodIMADBStatisticalValue: {
          minimumRate: '',
        },
        evalMethodAIHSCode: {
          range: {
            from: '',
            to: '',
          },
        },
      },
      payments: {
        methodOfPayment: '',
      },
    };

    if (
      values.evaluationMethods.evalMethodCalculator.activateMethod &&
      values.evaluationMethods.evalMethodWebValue.activateMethod
    ) {
      errors.evaluationMethods.evalMethodCalculator.globalError =
        errors.evaluationMethods.evalMethodWebValue.globalError =
          'You cannot select both `EvalMethod Calculator` and `EvalMethod Web Value`';
      tabsValidity.current[CustomerDetailsTabs.EVALUATION_METHODS] = false;
    }

    if (Number(values.evaluationMethods?.evalMethodWebValue?.minimumRate) > 1) {
      errors.evaluationMethods.evalMethodWebValue.minimumRate =
        'Evaluation method web value minimum rate should be less then 100%';
      tabsValidity.current[CustomerDetailsTabs.EVALUATION_METHODS] = false;
    }

    if (Number(values.evaluationMethods?.evalMethodIMADBStatisticalValue?.minimumRate) > 1) {
      errors.evaluationMethods.evalMethodIMADBStatisticalValue.minimumRate =
        'Evaluation method IMADB statistical value minimum rate should be less then 100%';
      tabsValidity.current[CustomerDetailsTabs.EVALUATION_METHODS] = false;
    }

    if (values.evaluationMethods?.evalMethodAIHSCode?.range.from !== undefined) {
      const fromValue = Number(values.evaluationMethods?.evalMethodAIHSCode?.range.from);
      if (fromValue < 0) {
        errors.evaluationMethods.evalMethodAIHSCode.range.from = 'From value should be greater then 0';
        tabsValidity.current[CustomerDetailsTabs.EVALUATION_METHODS] = false;
      }
      if (fromValue >= 1) {
        errors.evaluationMethods.evalMethodAIHSCode.range.from = 'From value should be less then 1';
        tabsValidity.current[CustomerDetailsTabs.EVALUATION_METHODS] = false;
      }
      if (values.evaluationMethods?.evalMethodAIHSCode?.range.to !== undefined) {
        const toValue = Number(values.evaluationMethods?.evalMethodAIHSCode?.range.to);
        if (toValue < fromValue) {
          errors.evaluationMethods.evalMethodAIHSCode.range.from = 'From value should be lower then to value';
          tabsValidity.current[CustomerDetailsTabs.EVALUATION_METHODS] = false;
        }
      }
    }

    if (values.evaluationMethods?.evalMethodAIHSCode?.range.to !== undefined) {
      const toValue = Number(values.evaluationMethods?.evalMethodAIHSCode?.range.to);
      if (toValue < 0) {
        errors.evaluationMethods.evalMethodAIHSCode.range.to = 'To value should be greater then 0';
        tabsValidity.current[CustomerDetailsTabs.EVALUATION_METHODS] = false;
      }
      if (toValue >= 1) {
        errors.evaluationMethods.evalMethodAIHSCode.range.to = 'To value should be less then 1';
        tabsValidity.current[CustomerDetailsTabs.EVALUATION_METHODS] = false;
      }
      if (values.evaluationMethods?.evalMethodAIHSCode?.range.from !== undefined) {
        const fromValue = Number(values.evaluationMethods?.evalMethodAIHSCode?.range.from);
        if (fromValue > toValue) {
          errors.evaluationMethods.evalMethodAIHSCode.range.to = 'To value should be greater then from value';
          tabsValidity.current[CustomerDetailsTabs.EVALUATION_METHODS] = false;
        }
      }
    }

    return errors;
  };

  const formik = useFormik<Customer>({
    initialValues: customerDetailsFormValuesBuilder(isCreate, generalDeclarant, customerDetails),
    onSubmit: () => {
      // pass
    },
    validate,
    validateOnMount: true,
    enableReinitialize: true,
  });

  const { handleBlur, handleChange, setFieldValue, values } = formik;

  useEffect(() => {
    if (!isCreate) {
      dispatch(fetchCustomerDetails(customerId));
    }
  }, []);

  const handleEditCustomer = (customerData: Partial<Customer>) => {
    dispatch(editCustomerDetails(customerData, customerId));
  };

  const handleSubmitCustomer = (type?: EditCustomerType) => {
    const handleCreateNewCustomer = () => {
      const navigationCallback = (customerId: Customer['_id']) => {
        history.push(`/customers/${customerId}`);
      };

      dispatch(
        createNewCustomer(
          {
            declarant: { ...formik.values.declarant, _id: generalDeclarant?._id },
            name: formik.values.name,
            payments: formik.values.payments,
            representative: formik.values.representative,
            evaluationMethods: formik.values.evaluationMethods,
          },
          navigationCallback,
        ),
      );
    };

    if (isCreate) {
      return handleCreateNewCustomer();
    }

    switch (type) {
      case EditCustomerType.DECLARANT: {
        return handleEditCustomer({
          name: formik.values.name,
          declarant: {
            ...formik.values.declarant,
            _id: customerDetailsDeclarant?._id || generalDeclarant._id,
          },
          representative: { ...customerDetails.representative, status: formik.values.representative.status },
        });
      }
      case EditCustomerType.REPRESENTATIVE: {
        return handleEditCustomer({
          name: formik.values.name,
          representative: formik.values.representative,
        });
      }
      case EditCustomerType.PAYMENTS: {
        return handleEditCustomer({
          name: formik.values.name,
          payments: { ...customerDetails.payments, ...formik.values.payments },
          personPayingTheCustomDuty: {
            ...customerDetails.personPayingTheCustomDuty,
            ...formik.values.personPayingTheCustomDuty,
          },
        });
      }
      case EditCustomerType.EVALUATION_METHODS: {
        return handleEditCustomer({
          name: formik.values.name,
          evaluationMethods: { ...customerDetails.evaluationMethods, ...formik.values.evaluationMethods },
        });
      }
      case EditCustomerType.DOCUMENTS: {
        return handleEditCustomer({
          name: formik.values.name,
          documents: { ...customerDetails.documents, ...formik.values.documents },
          personProvidingGuarantee: {
            ...customerDetails.personProvidingGuarantee,
            ...formik.values.personProvidingGuarantee,
          },
        });
      }
      case EditCustomerType.API_USER: {
        return handleEditCustomer({
          name: formik.values.name,
          externalUsers: formik.values.externalUsers,
        });
      }
      case EditCustomerType.TRANSIT_AUTH: {
        return handleEditCustomer({
          name: formik.values.name,
          transitAuthorisations: formik.values.transitAuthorisations,
        });
      }
      case EditCustomerType.GUARANTEES_TRANSIT: {
        return handleEditCustomer({
          name: formik.values.name,
          transitGuarantees: formik.values.transitGuarantees,
        });
      }
      default: {
        return handleCreateNewCustomer();
      }
    }
  };

  const handleGoBack = () => {
    history.push('/customers');
  };

  const handleUpdatePayments = (updatedPayments: Customer['payments']) => {
    if (isCreate && setFieldValue) {
      setFieldValue('payments', updatedPayments);
    }

    if (!isCreate) {
      dispatch(editCustomerDetails({ payments: updatedPayments }, customerId));
    }
  };

  const isButtonDisabled =
    (isCreate && !values.name) || !Object.keys(tabsValidity.current).every((key) => tabsValidity.current[key] === true);

  return (
    <PageContainer>
      <CustomerDetailsPageHeader>
        <CustomerDetailsBackButton onClick={handleGoBack}>
          <img src={backIcon} alt="" />
          Back
        </CustomerDetailsBackButton>
        <CustomerDetailsPageTitle>
          <CustomerDetailsFormContainer>
            <Input
              name="name"
              placeholder="Customer name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
              width={237}
            />
          </CustomerDetailsFormContainer>
        </CustomerDetailsPageTitle>
      </CustomerDetailsPageHeader>

      <Tabs style={{ padding: '0 50px', marginTop: '20px' }} contentStyle={{ padding: '0 50px', overflow: 'auto' }}>
        <Tab
          label={getDeclarantTabLabel(config?.COUNTRY)}
          hasError={!tabsValidity.current[CustomerDetailsTabs.DECLARANT]}
        >
          <CustomerDetailsDeclarant
            handleSubmitCustomer={handleSubmitCustomer}
            isButtonDisabled={isButtonDisabled}
            form={formik}
          />
        </Tab>
        {config?.COUNTRY === EnvironmentCountries.BE && (
          <Tab label="Representative (HV)" hasError={!tabsValidity.current[CustomerDetailsTabs.REPRESENTATIVE]}>
            <CustomerDetailsRepresentative
              handleSubmitCustomer={handleSubmitCustomer}
              isButtonDisabled={isButtonDisabled}
              form={formik}
            />
          </Tab>
        )}
        <Tab label="Payments">
          <CustomerDetailsPayments
            handleSubmitCustomer={handleSubmitCustomer}
            handleUpdatePayments={handleUpdatePayments}
            isButtonDisabled={isButtonDisabled}
            form={formik}
          />
        </Tab>
        <Tab label="Evaluation Methods" hasError={!tabsValidity.current[CustomerDetailsTabs.EVALUATION_METHODS]}>
          <EvaluationMethods
            form={formik}
            handleSubmitCustomer={handleSubmitCustomer}
            isButtonDisabled={isButtonDisabled}
          />
        </Tab>
        <Tab label="Guarantees for Import">
          <CustomerDetailsDocuments
            handleSubmitCustomer={handleSubmitCustomer}
            isButtonDisabled={isButtonDisabled}
            form={formik}
          />
        </Tab>
        <Tab label="API User">
          <CustomerDetailsApiUser form={formik} handleSubmitCustomer={handleSubmitCustomer} />
        </Tab>
        <Tab label="Transit authorizations">
          <CustomerDetailsTransitAuth form={formik} handleSubmitCustomer={handleSubmitCustomer} />
        </Tab>
        <Tab label="Guarantees for transit">
          <CustomerDetailsGuaranteesTransit
            form={formik}
            handleSubmitCustomer={handleSubmitCustomer}
            isButtonDisabled={isButtonDisabled}
          />
        </Tab>
      </Tabs>
    </PageContainer>
  );
};

export default CustomerDetails;
