import styled from 'styled-components';
import tw from 'twin.macro';

interface ISectionTitleProps {
  noTopBorder?: boolean;
  spaceBetween?: boolean;
}

export const SectionTitle = styled.div<ISectionTitleProps>`
  ${tw`
    font-extrabold
  `}
  border-top: ${({ noTopBorder }) => (noTopBorder ? 'none' : '1px solid #dedede')};
  padding: ${({ noTopBorder }) => (noTopBorder ? '10px 0 10px 0' : '25px 0 10px 0')};
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.36px;
  justify-content: ${({ spaceBetween }) => (spaceBetween ? 'space-between' : 'unset')};
`;

export const InvalidationInfoContainer = styled.div`
  ${tw`
  flex
  items-start
`}

  & > div {
    margin-left: 50px;

    &:first-of-type {
      margin-left: 0;
      width: 50%;
    }
  }
`;

export const InvalidationRequestForm = styled.div`
  ${tw`
    flex
    flex-col
  `}

  textarea {
    margin: 10px 0;
    border: 1px solid ${({ theme }) => theme.colors.input.border};
    border-radius: 5px;
    padding: 10px;
  }

  button {
    margin-top: 15px;
  }
`;

export const InvalidationDecisionContainer = styled.div`
  ${tw`
    flex
    flex-col
  `};

  & > div:last-of-type {
    margin-top: 30px;
    textarea {
      max-width: 50%;
    }
  }
`;

export const InvalidationDecisionStatusRow = styled.div`
  ${tw`
    flex
    items-start
  `}
  position: relative;
  top: 20px;

  & > div {
    margin-left: 50px;

    &:first-of-type {
      margin-left: 0;
    }
  }
`;
