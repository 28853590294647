import { Customer, EnvironmentCountries } from '@e-origin/shared';
import { FormikProps } from 'formik';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useConfig } from '../../../hooks/use-config';
import { Autocomplete, Button, Input, InputHeader } from '../../../components';
import { EditCustomerType } from '../../../enums';
import { FormRow, FormSection, FormSectionTitle } from '../../../styles/common';
import { CustomerDetailsParamsType, CustomerDetailsTabType } from '../customer-details.component';
import * as Style from './customer-details-declarant.styles';
import { retrieveCodes } from '../../../stores/settingsSlice';
import { selectDeclarant } from '../../../stores/declarantSlice';

interface CustomerDetailsDeclarantProps extends CustomerDetailsTabType {
  form: FormikProps<Customer>;
}

const CustomerDetailsDeclarant: React.FC<CustomerDetailsDeclarantProps> = (props) => {
  const { handleSubmitCustomer, isButtonDisabled, form } = props;

  const { declarant } = form.values;
  const generalDeclarant = useSelector(selectDeclarant);
  const { config } = useConfig();

  const { handleBlur, handleChange, setFieldValue } = form;
  const { customerId } = useParams<CustomerDetailsParamsType>();
  const context = customerId === 'add' ? 'CREATE' : 'UPDATE';

  return (
    <Style.Container>
      <FormSection>
        <FormRow>
          <Input
            name="declarant.name"
            placeholder="Name"
            onChange={handleChange}
            onBlur={handleBlur}
            value={declarant.name}
            width={387}
          />
          <Input
            name="declarant.identificationNumber"
            placeholder="Identification number"
            onChange={handleChange}
            onBlur={handleBlur}
            value={declarant.identificationNumber}
            width={237}
          />
          {config?.COUNTRY === EnvironmentCountries.NL && (
            <Autocomplete
              key={form.values.representative.status}
              width={237}
              widthUnit="px"
              placeholder="Status"
              fetchOptions={(search: string) => retrieveCodes('CL094', search, generalDeclarant.language)}
              onChange={(selectedOption) => form.setFieldValue('representative.status', Number(selectedOption?.value))}
              value={{
                value: form.values.representative.status.toString(),
                label: form.values.representative.status.toString(),
              }}
            />
          )}
          {config?.COUNTRY === EnvironmentCountries.BE && (
            <InputHeader
              name="declarant.identificationNumberIDMS"
              headerPlaceholder="Use Importer EORI"
              placeholder={'Identification number IDMS'}
              onChange={handleChange}
              onBlur={handleBlur}
              value={declarant.identificationNumberIDMS}
              width={370}
              disabled={declarant.useImporterEORI}
              defaultBehaviour
              onHeaderChange={(value) => {
                if (value) {
                  setFieldValue('declarant.identificationNumberIDMS', '');
                }
                setFieldValue('declarant.useImporterEORI', value);
              }}
              headerValue={declarant.useImporterEORI}
            />
          )}
        </FormRow>
        <FormRow>
          <Input
            name="declarant.address.street"
            placeholder="Address"
            onChange={handleChange}
            onBlur={handleBlur}
            value={declarant.address?.street}
            width={387}
          />
          <Input
            name="declarant.address.postcode"
            placeholder="Postcode"
            onChange={handleChange}
            onBlur={handleBlur}
            value={declarant.address?.postcode}
            width={180}
          />
          <Input
            name="declarant.address.city"
            placeholder="City"
            onChange={handleChange}
            onBlur={handleBlur}
            value={declarant.address?.city}
            width={180}
          />
          <Input
            name="declarant.address.country"
            placeholder="Country"
            onChange={handleChange}
            onBlur={handleBlur}
            value={declarant.address?.country}
            width={180}
          />
        </FormRow>
        <FormRow>
          <Input
            name="declarant.authorisedIdentity"
            placeholder="Authorised identity"
            onChange={handleChange}
            onBlur={handleBlur}
            value={declarant.authorisedIdentity}
            width={387}
          />
        </FormRow>
      </FormSection>
      <FormSection noTopPadding>
        <FormSectionTitle>Contact person</FormSectionTitle>
        <FormRow>
          <Input
            name="declarant.contactPerson.name"
            placeholder="Name"
            onChange={handleChange}
            onBlur={handleBlur}
            value={declarant.contactPerson?.name}
          />
          <Input
            name="declarant.contactPerson.phoneNumber"
            placeholder="Phone number"
            onChange={handleChange}
            onBlur={handleBlur}
            value={declarant.contactPerson?.phoneNumber}
          />
          <Input
            name="declarant.contactPerson.email"
            placeholder="E-mail"
            onChange={handleChange}
            onBlur={handleBlur}
            value={declarant.contactPerson?.email}
          />
        </FormRow>
      </FormSection>
      <Style.SubmitSection>
        <Button
          disabled={isButtonDisabled}
          onClick={() => handleSubmitCustomer(EditCustomerType.DECLARANT)}
          primary
          type="submit"
        >
          {context === 'CREATE' ? 'Create' : 'Save'}
        </Button>
      </Style.SubmitSection>
    </Style.Container>
  );
};

export default CustomerDetailsDeclarant;
