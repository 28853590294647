/* eslint-disable max-lines */
import _ from 'lodash';
import moment from 'moment';
/* eslint-disable complexity */
import { useState } from 'react';
import { useDispatch } from 'react-redux';

/* eslint-disable max-statements */
import { isDeparture, NCTSMessageType, Transit, TransitStatus } from '@e-origin/shared';

import { Button } from '../../../../../components';
import { invalidateTransit } from '../../../../../stores/transitsSlice';
import { FormRow } from '../../../../../styles/common';
import {
  InvalidationDecisionContainer,
  InvalidationDecisionStatusRow,
  InvalidationInfoContainer,
  InvalidationRequestForm,
  SectionTitle,
} from './transit-invalidation.style';

const canDisplayRequestInvalidationButton = (transit: Transit): boolean =>
  [TransitStatus.ACCEPTED, TransitStatus.GUARANTEE_NOT_VALID].includes(transit.generalInfo.status) &&
  !transit.customsState?.invalidationRequest?.invalidationRequestDate;

interface TransitInvalidationProps {
  transit: Transit;
}

export const TransitInvalidation = ({ transit: transitObj }: TransitInvalidationProps) => {
  const transit = _.cloneDeep(transitObj);
  const transitIsDeparture = isDeparture(transit);

  const [invalidationRequestReason, setInvalidationRequestReason] = useState(
    transit?.customsState?.invalidationRequest?.invalidationReason || '',
  );

  const dispatch = useDispatch();

  const handleInvalidationReasonChange = (e: any) => {
    setInvalidationRequestReason(e.target.value);
  };

  const handleSendInvalidationRequest = () => {
    dispatch(invalidateTransit(transit, invalidationRequestReason));
  };

  // errors - if invalidation form is sent and customs sends back errors
  const errors = transit.customsState?.functionalError?.filter((error) =>
    error.errorPointer.toLocaleLowerCase().includes(NCTSMessageType.CC014C.toLocaleLowerCase()),
  );

  return (
    <>
      {/** invalidation info */}
      {transitIsDeparture &&
        [TransitStatus.ACCEPTED, TransitStatus.GUARANTEE_NOT_VALID].includes(transit.generalInfo?.status) && (
          <>
            <SectionTitle>Invalidation</SectionTitle>
            <InvalidationInfoContainer>
              <InvalidationRequestForm>
                {errors?.length > 0 &&
                  errors.map((error, idx) => (
                    <FormRow key={idx}>
                      <p>
                        Error pointer: {error.errorPointer} (Error code: {error.errorCode}) {error.errorReason}{' '}
                        {error.originalAttributeValue}
                      </p>
                    </FormRow>
                  ))}
                <label>Invalidation reason</label>
                <textarea
                  value={invalidationRequestReason}
                  onChange={handleInvalidationReasonChange}
                  disabled={!!transit.customsState?.invalidationRequest?.invalidationRequestDate}
                />
                {canDisplayRequestInvalidationButton(transit) && (
                  <div>
                    <Button
                      type="button"
                      primary
                      disabled={!invalidationRequestReason.length}
                      onClick={handleSendInvalidationRequest}
                    >
                      Request invalidation
                    </Button>
                  </div>
                )}
              </InvalidationRequestForm>
              {!!transit.customsState?.invalidationRequest?.invalidationRequestDate && (
                <div>
                  <label>Invalidation request date</label>
                  <p>
                    {moment(transit.customsState?.invalidationRequest?.invalidationRequestDate).format('YYYY-MM-DD')}
                  </p>
                </div>
              )}
            </InvalidationInfoContainer>
          </>
        )}
      {/** /invalidation info */}
      {/** invalidation decision */}
      {transit.customsState?.invalidationDecision && (
        <InvalidationDecisionContainer>
          <InvalidationDecisionStatusRow>
            <div>
              <label>Invalidation decision date</label>
              <p>{transit.customsState?.invalidationDecision?.invalidationDecisionDate}</p>
            </div>
            <div>
              <label>Invalidation request date</label>
              <p>{transit.customsState?.invalidationRequest?.invalidationRequestDate}</p>
            </div>
            <div>
              <label>Invalidation initiated by customs</label>
              <p>
                {Number(transit.customsState?.invalidationDecision?.invalidationInitiatedByCustoms) === 1
                  ? 'yes'
                  : 'no'}
              </p>
            </div>
          </InvalidationDecisionStatusRow>
          <InvalidationRequestForm>
            <label>Invalidation reason</label>
            <textarea
              value={
                transit.customsState?.invalidationDecision?.invalidationReason ||
                transit.customsState?.invalidationRequest?.invalidationReason
              }
              disabled
            />
          </InvalidationRequestForm>
        </InvalidationDecisionContainer>
      )}
      {/** /invalidation decision */}
    </>
  );
};
