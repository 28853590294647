import styled from 'styled-components';

export const RiskAnalysisHsCodeSectionTitle = styled.div`
  display: flex;
  align-items: center;

  h4 {
    margin-top: 4px;
  }

  button {
    width: 30px;
    height: 30px;
    padding: 4px;
    border: 1px solid #ccc;
    background: transparent;
    border-radius: 8px;
    text-align: center;
    box-shadow:
      0px 1px 2px 0px rgba(60, 64, 67, 0.302),
      0px 1px 3px 1px rgba(60, 64, 67, 0.149);

    &:hover {
      background: #f6f9ff;
    }
  }

  & * + * {
    margin-left: 10px;
  }
`;

export const RiskAnalysisHSCodeInfo = styled.div`
  font-size: 13px;
  color: #1870a3;
  margin-top: 10px;
  height: 30;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${({ isLink }: { isLink: boolean }) => {
    if (isLink) {
      return `
        cursor: pointer;
        text-decoration: none;
        line-height: 1.3;
        font-weight: 500;

        &:hover {
          text-decoration: underline;
        }
      `;
    }
    return `cursor: grab`;
  }};
`;

export const RiskAnalysisHSCodeInputWrapper = styled.div`
  flex-basis: 50%;
`;

export const RiskAnalysisHSCodeInfoValue = styled.div`
  font-size: 13px;
  color: #1870a3;
  font-weight: 300;
`;

export const HsCodeDescriptionInfo = styled.div`
  display: flex;

  & > * {
    flex-grow: 1;
    flex-basis: 0;
  }

  & > * + * {
    margin-left: 15px;
  }
`;

export const RiskAnalysisHSCodeWebsiteSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`;

export const HsCodeDescriptionInfoItem = styled.div``;

export const HsCodeDescriptionInfoItemHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;

export const HsCodeDescriptionInfoItemTitle = styled.h4`
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 5px;
`;
