import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;

  * + * {
    margin-left: 10px;
  }
`;

export const InputWrapper = styled.div`
  width: 50%;
`;

export const MessageInfo = styled.div`
  margin-top: 5px;
  color: #ff0000;
`;
