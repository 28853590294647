import styled from 'styled-components';

export const Container = styled.div``;

export const FieldContainer = styled.div`
  margin: 24px 0;
`;

export const InputWrapper = styled.div`
  width: 100%;
  display: flex;

  &:first-child {
    flex: 1 0 !important;
  }
`;

export const DownloadButton = styled.div`
  margin: 32px 0;
`;

export const BadgesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 15px;
`;

export const Badge = styled.div`
  background: #1770a3;
  color: white;
  line-height: 1;
  padding: 4px 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 14px;
  height: 22px;
  font-size: 12px;
`;

export const CloseBadge = styled.button`
  margin-left: 10px;
  line-height: 1;
`;

export const SuggestionWrapper = styled.div`
  position: relative;
`;

export const SuggestionLabel = styled.div`
  color: #555454;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  font-size: 13px;
  top: 3px;
  line-height: 15px;
`;

export const SuggestionTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: #555454;
  line-height: 19px;
`;

export const SuggestionHighligh = styled.span`
  font-weight: 700;
`;
