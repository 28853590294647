import styled from 'styled-components';
import tw from 'twin.macro';

export const Container = styled.div`
  ${tw`
    flex
    flex-col
    flex-shrink-0
    mt-8
  `}
`;

export const ActionButton = styled.div`
  ${tw`
    flex
    py-3
  `}

  &> * {
    margin-left: 10px;
  }
`;

export const BatchList = styled.div`
  ${tw`
    flex
    flex-row
    flex-shrink-0
    flex-wrap
    gap-4
  `}
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const BatchItem = styled.div`
  margin-top: 8px;
  padding: 8px;
  border: 1px solid #1770a3;
  color: #1770a3;
  font-weight: bold;
  border-radius: 5px;
`;
