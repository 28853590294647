/* eslint-disable complexity */
import { TransitStatus } from '@e-origin/shared';

export const transitExtractStatus = (status: TransitStatus | undefined): string => {
  const statuses = new Map<TransitStatus, string>([
    [TransitStatus.NOT_SENT, 'Not sent'],
    [TransitStatus.SENDING, 'Sending'],
    [TransitStatus.INVALIDATION_PENDING, 'Invalidation Pending'],
    [TransitStatus.INVALIDATED, 'Invalidated'],
    [TransitStatus.GUARANTEE_NOT_VALID, 'Guarantee Not Valid'],
    [TransitStatus.CORR_REFUSED_WAITING, 'CORR REFUSED WAITING'],
    [TransitStatus.CORR_OK_WAITING, 'CORR OK WAITING'],
    [TransitStatus.SENT, 'Sent'],
    [TransitStatus.REJECTED, 'Rejected'],
    [TransitStatus.ACCEPTED, 'Accepted'],
    [TransitStatus.UNLOAD_PERMISSION, 'Unload permission'],
    [TransitStatus.REMARKS_SENT, 'Remarks sent'],
    [TransitStatus.IN_CONTROL, 'In control'],
    [TransitStatus.NOT_RELEASED, 'Not released'],
    [TransitStatus.RELEASED, 'Released'],
    [TransitStatus.FINISHED, 'Finished'],
    [TransitStatus.DISCREPANCIES, 'Discrepancies'],
    [TransitStatus.REQUESTED_NON_ARRIVED, 'Requested non arrived info'],
  ]);

  return statuses.get(status) || 'Unknown status';
};
