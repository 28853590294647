import styled from 'styled-components';
import tw from 'twin.macro';

export const NonArrivalInfoContainer = styled.div`
  ${tw`
  flex
  items-start
`}

  & > div {
    margin-left: 50px;

    &:first-of-type {
      margin-left: 0;
      width: 50%;
    }
  }
`;

export const NonArrivalForm = styled.div`
  ${tw`
    flex
    flex-col
  `}

  textarea {
    margin: 10px 0;
    border: 1px solid ${({ theme }) => theme.colors.input.border};
    border-radius: 5px;
    padding: 10px;
  }

  button {
    margin-top: 15px;
  }
`;
