import { useState } from 'react';

import { Autocomplete, Button } from '../../../../components';
import { searchArchivedBatches } from '../../../../stores/batchesSlice';
import { PageTitle } from '../../../../styles/common';
import * as Style from './unarchive-batch.style';

interface UnarchiveBatchesProps {
  unarchive: (batchName: string[]) => Promise<void>;
  title: string;
}

export const UnarchiveBatches: React.FC<UnarchiveBatchesProps> = (props) => {
  const [batches, setBatches] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const retrieveBatches = async (search: string): Promise<any> => {
    const results = await searchArchivedBatches(search);

    return results
      ?.filter((r) => !batches.some((b) => b.value === r.batchName))
      .map((result) => ({
        value: result.batchName,
        label: (
          <Style.SuggestionWrapper>
            <Style.SuggestionTitle>{result.batchName}</Style.SuggestionTitle>
            <Style.SuggestionLabel>{result.mrn}</Style.SuggestionLabel>
          </Style.SuggestionWrapper>
        ),
      }));
  };

  const handleUnarchiveBatcheses = async () => {
    setIsLoading(true);
    await props.unarchive(batches.map((batch) => batch.value));
    setIsLoading(false);
    setBatches([]);
  };

  const removeBatch = (value: string) => {
    setBatches(batches.filter((batch) => batch.value !== value));
  };

  const addBatch = (batch) => {
    setBatches([...batches, batch]);
  };

  return (
    <Style.Container>
      <PageTitle>{props.title}</PageTitle>
      <Style.FieldContainer>
        <Style.InputWrapper>
          <Autocomplete
            placeholder="Type a batch name or MRN"
            fetchOptions={(search: string) => retrieveBatches(search)}
            onSelect={(selectedOption) => {
              addBatch(selectedOption);
            }}
            value={{
              value: '',
              label: '',
            }}
          />
          <Button primary style={{ marginLeft: 15, height: 65 }}>
            +
          </Button>
        </Style.InputWrapper>
        {!!batches.length && (
          <Style.BadgesContainer>
            {batches.map((batch) => (
              <Style.Badge key={batch.value}>
                {batch.value}{' '}
                <Style.CloseBadge onClick={() => removeBatch(batch.value)} style={{ height: '20px' }}>
                  X
                </Style.CloseBadge>
              </Style.Badge>
            ))}
          </Style.BadgesContainer>
        )}
      </Style.FieldContainer>

      <Style.DownloadButton>
        <Button showLoading={isLoading} primary onClick={handleUnarchiveBatcheses} disabled={batches.length === 0}>
          {batches.length > 1 ? 'Unarchive batches' : 'Unarchive batch'}
        </Button>
      </Style.DownloadButton>
    </Style.Container>
  );
};
