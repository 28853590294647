import { useDispatch } from 'react-redux';
import { deleteTransit } from '../../../../../src/stores/transitsSlice';
import { NotificationModal } from '../../../../components';

interface ITransitDeleteModalProps {
  transitId: string;
  open: boolean;
  onHide: () => void;
  onFinish: () => void;
}
export const TransitDeleteModal: React.FC<ITransitDeleteModalProps> = (props) => {
  const dispatch = useDispatch();
  return (
    <NotificationModal
      title="Confirm transit delete"
      confirmButtonText="Delete"
      show={props.open}
      onHide={props.onHide}
      onConfirm={() => {
        dispatch(deleteTransit(props.transitId));
      }}
      isDelete={true}
    >
      <div>Are you sure you want to delete this transit? This action cannot be undone!</div>
    </NotificationModal>
  );
};
