/* eslint-disable complexity */
/* eslint-disable max-lines */
import moment from 'moment';
import { useEffect, useRef } from 'react';
import { IDataTableColumn } from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

/* eslint-disable max-statements */
import { Declaration, DeclarationCustomsFlowType, DeclarationCustomsType, DeclarationStatus } from '@e-origin/shared';

import { TableActionsIcons } from '../../../assets';
import { CustomTableActionsComponent, DeclarationModalEnum } from '../../../components';
import { DeclarationFlowBackgroundColors } from '../../../enums';
import { canBeDuplicated, canBeRegularized } from '../../../helpers/declaration.helper';
import { sendBatchToBeGate } from '../../../stores/batchesSlice';
import {
  selectDeclarationsFilters,
  sendAllDeclarationsWithSameLRN,
  sendOneHighValue,
  sendOneLowValue,
} from '../../../stores/declarationsSlice';
import { selectSettings } from '../../../stores/settingsSlice';
import { BadgeWrapper } from '../../../styles/common';
import DeclarationStatusIndicator from './declaration-status-indicator/declaration-status-indicator.component';

export const useDeclarationsTableColumns = ({ openModal, fetchData, onSelectedRow }) => {
  const history = useHistory();

  const dispatch = useDispatch();

  const filters = useSelector(selectDeclarationsFilters);

  const settings = useSelector(selectSettings);

  const highValuesActions = (row: Declaration) => {
    if (row.generalInfo.group !== 'H1') {
      return [];
    }

    return [
      {
        if: canBeRegularized(row),
        icon: TableActionsIcons.COPY,
        text: `${
          row?.customsState?.sentTo === DeclarationCustomsType.PLDA ? 'Regularization' : 'Amendment'
        } or Duplication`,
        onClick: () => openModal(row, DeclarationModalEnum.REGULARIZATION),
      },
      {
        if: canBeDuplicated(row),
        icon: TableActionsIcons.COPY,
        text: 'Duplicate because of customs invalidation',
        onClick: () => openModal(row, DeclarationModalEnum.DUPLICATE),
      },
      {
        if: row.generalInfo.status === DeclarationStatus.ACCEPTED,
        icon: TableActionsIcons.DANGEROUS,
        text: 'Move to customs control',
        onClick: () => openModal(row, DeclarationModalEnum.CUSTOMS_CONTROL),
      },
      {
        if:
          row.generalInfo?.status === DeclarationStatus.REJECTED ||
          (row.generalInfo?.status === DeclarationStatus.ACCEPTED &&
            row.customsState?.flow === DeclarationCustomsFlowType.PRE),
        icon: TableActionsIcons.SEND,
        text: 'Send',
        onClick: () => {
          if (
            [DeclarationCustomsType.IDMS, DeclarationCustomsType.AES, DeclarationCustomsType.DMS].includes(
              row.customsState?.sentTo,
            )
          ) {
            sendOneHighValue(row.messageInfo.LRN);
            return;
          }

          if (row.customsState?.sentTo === DeclarationCustomsType.BE_GATE) {
            (dispatch(sendBatchToBeGate(row.messageInfo.batch, row.messageInfo.LRN)) as any).then(() => fetchData());
            return;
          }

          sendAllDeclarationsWithSameLRN(row.messageInfo.batch, '', [row.messageInfo.LRN]).then(() => fetchData());
        },
      },
    ];
  };

  const lowValuesActions = (row: Declaration) => {
    if (row.generalInfo.group === 'H1') {
      return [];
    }

    return [
      {
        if: (() => {
          if (
            row.generalInfo?.status === DeclarationStatus.NOT_SENT ||
            row.generalInfo?.status === DeclarationStatus.REJECTED ||
            (row.generalInfo?.status === DeclarationStatus.ACCEPTED &&
              row.customsState?.flow === DeclarationCustomsFlowType.PRE &&
              !row.customsState?.invalidationRequest?.invalidationRequestDate)
          ) {
            return true;
          }

          if (
            row.generalInfo?.status === DeclarationStatus.SENT &&
            Math.abs(moment.duration(moment().diff(moment(row.updatedAt, 'DD-MM-YY @ HH:mm'))).asMinutes()) >=
              settings.reSendDelay
          ) {
            return true;
          }

          return false;
        })(),
        icon: TableActionsIcons.SEND,
        text: 'Send',
        onClick: () => {
          sendOneLowValue(row._id as string).then(() => fetchData());
        },
      },
    ];
  };

  const commonActions = (row: Declaration) => {
    return [
      {
        icon: TableActionsIcons.VIEW,
        text: 'View details',
        onClick: () => {
          history.push(`/declarations/${row._id}`);
        },
      },
    ];
  };

  const buildColumns = (): IDataTableColumn<Declaration>[] => [
    {
      name: 'Checked',
      width: '60px',
      cell: (row) => <DeclarationStatusIndicator declaration={row} />,
    },
    {
      name: 'Batch ID',
      selector: 'messageInfo.batchId',
      sortable: true,
      width: '100px',
    },
    ...(filters.group === 'H1'
      ? [
          {
            name: 'Flow/System',
            width: '130px',
            cell: (row) => (
              <div className="whitespace-nowrap overflow-hidden overflow-ellipsis">
                {row.customsState?.sentTo && (
                  <BadgeWrapper
                    style={{ backgroundColor: DeclarationFlowBackgroundColors[row.customsState?.flow ?? 'POST'] }}
                  >
                    {row.customsState.flow?.toLowerCase() || ''}
                    {row.customsState.sentTo}
                  </BadgeWrapper>
                )}
              </div>
            ),
          },
          {
            name: 'HS Code',
            width: '120px',
            cell: (row: Declaration) => (
              <div className="whitespace-nowrap overflow-hidden overflow-ellipsis">
                {row.goodShipmentGlobal?.firstItemData?.hsCode &&
                row.goodShipmentGlobal?.firstItemData?.hsCode.toString().length
                  ? row.goodShipmentGlobal?.firstItemData?.hsCode
                  : 'N/A'}
              </div>
            ),
          },
          {
            name: 'Description',
            width: '160px',
            cell: (row: Declaration) => (
              <div className="whitespace-nowrap overflow-hidden overflow-ellipsis">
                {row.goodShipmentGlobal?.firstItemData?.invoiceDescription &&
                row.goodShipmentGlobal?.firstItemData?.invoiceDescription.length
                  ? row.goodShipmentGlobal?.firstItemData?.invoiceDescription
                  : 'N/A'}
              </div>
            ),
          },
          {
            name: 'Sequence Number',
            width: '100px',
            cell: (row: Declaration) => (
              <div className="whitespace-nowrap overflow-hidden overflow-ellipsis">
                {row.goodShipmentGlobal?.sequence || ''}
              </div>
            ),
          },
          {
            name: 'Item declared value',
            width: '100px',
            cell: (row) => (
              <div className="whitespace-nowrap overflow-hidden overflow-ellipsis">
                {row.goodShipmentGlobal?.totalAmountInvoiced || 'N/A'}
              </div>
            ),
          },
        ]
      : []),
    {
      name: 'Number of items',
      width: '100px',
      cell: (row) => (
        <div className="whitespace-nowrap overflow-hidden overflow-ellipsis">
          {row.goodsShipmentItemsLength || 'N/A'}
        </div>
      ),
    },
    ...(filters.group !== 'H1'
      ? [
          {
            name: 'Flow/System',
            width: '130px',
            cell: (row) => (
              <div className="whitespace-nowrap overflow-hidden overflow-ellipsis">
                {row.customsState?.sentTo && (
                  <BadgeWrapper
                    style={{ backgroundColor: DeclarationFlowBackgroundColors[row.customsState?.flow ?? 'POST'] }}
                  >
                    {row.customsState?.flow?.toLowerCase() || ''}
                    {row.customsState.sentTo || ''}
                  </BadgeWrapper>
                )}
              </div>
            ),
          },
          {
            name: 'Intrinsic value',
            selector: (row: Declaration) =>
              row.consignment?.intrinsicValue?.amount && row.consignment?.intrinsicValue?.currencyCode
                ? `${row.consignment.intrinsicValue.amount} ${row.consignment.intrinsicValue.currencyCode}`
                : '',
          },
        ]
      : []),
    {
      name: 'Gross Mass',
      selector: (row) => {
        return row?.generalInfo?.group === 'H1'
          ? row.goodShipmentGlobal?.totalGrossMass || 0
          : row?.consignment?.grossMass || 0;
      },
      width: '120px',
    },
    {
      name: 'Dest',
      selector: (row) => row.stakeholders.importer.address.country,
      width: '80px',
    },
    {
      name: 'Exporter',
      selector: (row) => row.stakeholders.exporter.name,
    },
    ...(filters.group === 'H1'
      ? [
          {
            name: 'Registration Number',
            selector: (row: Declaration) => row.otherDataElements?.registrationNumber,
          },
        ]
      : [
          {
            name: 'Reference UCR',
            selector: (row: Declaration) => row.goodsShipmentItems?.[0]?.referenceNumberUCR,
          },
        ]),
    {
      name: 'LRN',
      selector: (row) => row.messageInfo.LRN,
    },
    {
      name: 'Date',
      selector: 'updatedAt',
      sortable: true,
      width: '160px',
    },
    {
      name: 'AWB',
      selector: 'messageInfo.transport',
    },
    {
      name: 'Actions',
      right: true,
      width: '160px',
      cell: (row) => (
        <CustomTableActionsComponent
          actions={[...highValuesActions(row), ...commonActions(row), ...lowValuesActions(row)]}
          secondaryActions={[]}
          onClick={() => onSelectedRow(row)}
          disableAfterClick={true}
        />
      ),
    },
  ];

  const columns = useRef<IDataTableColumn<Declaration>[]>(buildColumns());

  useEffect(() => {
    columns.current = buildColumns();
  }, [filters.group, settings]);

  return columns;
};
