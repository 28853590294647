/* eslint-disable complexity */
import { useEffect, useRef, useState } from 'react';

import { Transit } from '@e-origin/shared';
import { TransitDeleteModal } from './transit-delete-modal/transit-delete-modal.component';

export enum TransitModalEnum {
  DELETE = 'DELETE',
}

interface ITransitModalProps {
  show: boolean;
  selectedData: Transit;
  modal: TransitModalEnum;
  onHide: () => void;
  onFinish: () => void;
}

export const TransitModal: React.FC<ITransitModalProps> = (props) => {
  const data = useRef<Transit>(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (props.show !== undefined) {
      setOpen(props.show);
      data.current = props.selectedData;
    }
  }, [props.show]);

  const onHide = () => {
    setOpen(false);
    data.current = null;
    props.onHide();
  };

  return (
    <>
      {open && (
        <>
          {props.modal === TransitModalEnum.DELETE && (
            <TransitDeleteModal transitId={data.current._id} open={open} onHide={onHide} onFinish={props.onFinish} />
          )}
        </>
      )}
    </>
  );
};
