import { FormikProps } from 'formik';
/* eslint-disable default-case */
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
/* eslint-disable complexity */
import { useHistory } from 'react-router-dom';

import { Transit, TransitGroup } from '@e-origin/shared';

import { selectTransit } from '../../../../src/stores/transitsSlice';
import backIcon from '../../../assets/icons/back-icon.svg';
import { Input } from '../../../components';
import { transitExtractStatus } from '../transit-details-extract-status';
import { TransitDetailsFormProps } from '../transit-details.component';
import * as Style from './transit-details-header.style';

interface TransitDetailsProps {
  transitDetails: Transit;
  editDisabled: boolean;
  form: FormikProps<TransitDetailsFormProps>;
}

const TransitDetailsHeader: React.FC<TransitDetailsProps> = ({ transitDetails }) => {
  const history = useHistory();
  const transitInfo = useSelector(selectTransit);

  const status = useMemo(
    () => transitExtractStatus(transitInfo?.generalInfo?.status),
    [transitInfo?.generalInfo?.status],
  );

  const isArrival = transitDetails?.generalInfo?.group === TransitGroup.A;

  return (
    <Style.Header>
      <Style.BackButton
        onClick={() => {
          history.goBack();
        }}
      >
        <img src={backIcon} alt="" />
        Back
      </Style.BackButton>
      <Style.Summary>
        <div>
          <label>Transit name</label>
          <p>{transitDetails.name}</p>
        </div>
        <div>
          <label>Status</label>
          <p className="status">{status}</p>
        </div>
        {!isArrival && (
          <Input
            width={250}
            widthUnit="px"
            placeholder="LRN"
            value={transitDetails?.TransitOperation?.LRN}
            disabled
            compact
            defaultBehaviour
          />
        )}
        <div>
          <Input
            width={250}
            widthUnit="px"
            placeholder="MRN"
            value={transitDetails?.TransitOperation?.MRN}
            disabled
            compact
            defaultBehaviour
          />
        </div>
      </Style.Summary>
      <Style.CommonInputs>
        <Style.CommonInputsRow>
          <Input
            width={100}
            widthUnit="px"
            placeholder="Type"
            value={transitDetails?.TransitOperation?.declarationType}
            disabled
            compact
            defaultBehaviour
          />

          <Input
            width={90}
            widthUnit="px"
            placeholder="Security"
            value={transitDetails?.TransitOperation?.security}
            disabled
            compact
            defaultBehaviour
          />
          {!isArrival && (
            <Input
              placeholder="Coverage"
              value={transitDetails?.generalInfo?.totalAmountGuaranteeToBeCovered}
              width={120}
              disabled
              compact
              defaultBehaviour
            />
          )}
          <Input
            width={110}
            widthUnit="px"
            placeholder="Packages"
            value={transitDetails?.Consignment?.totalNumberOfPackages}
            disabled
            compact
            defaultBehaviour
          />

          <Input
            width={130}
            widthUnit="px"
            placeholder="Total Gross"
            value={transitDetails?.Consignment?.grossMass || ''}
            disabled
            compact
            defaultBehaviour
          />
          <Input
            width={90}
            widthUnit="px"
            placeholder="Seals"
            value={transitDetails?.Consignment?.totalNumberOfSeals || ''}
            disabled
            compact
            defaultBehaviour
          />
          <Input
            placeholder="Reduced"
            width={120}
            widthUnit="px"
            value={transitDetails?.TransitOperation?.reducedDatasetIndicator}
            disabled
            compact
            defaultBehaviour
          />
          {!isArrival && (
            <Input
              width={150}
              widthUnit="px"
              placeholder="Destination declared"
              value={transitDetails?.CustomsOfficeOfDestinationDeclared?.referenceNumber || ''}
              disabled
              compact
              defaultBehaviour
            />
          )}
          {isArrival && (
            <Input
              width={280}
              widthUnit="px"
              placeholder="Destination actual"
              value={transitDetails?.CustomsOfficeOfDestinationActual?.referenceNumber || ''}
              disabled
              compact
              defaultBehaviour
            />
          )}

          <Input
            width={110}
            widthUnit="px"
            placeholder="Simplified"
            value={transitDetails?.TransitOperation?.simplifiedProcedure}
            disabled
            compact
            defaultBehaviour
          />
        </Style.CommonInputsRow>
      </Style.CommonInputs>
    </Style.Header>
  );
};

export default TransitDetailsHeader;
