/* eslint-disable complexity */
import { Menu, MenuItem } from '@szhsin/react-menu';
import { useEffect, useRef, useState } from 'react';

import { TransitTypeEnum } from '@e-origin/shared';

import contextualIcon from '../../assets/icons/contextual-icon.svg';
import downloadIcon from '../../assets/icons/download-icon.svg';
import { useConfig } from '../../hooks/use-config';
import { IconContainer } from '../table-action-buttons/table-action-buttons.style';
import * as Style from './custom-table-actions.style';

interface IAction {
  if?: boolean;
  icon: string;
  text: string;
  onClick: () => void;
  disabled?: boolean;
  disableAfterClick?: boolean;
  preventDefaultClick?: boolean;
}

interface CustomTableActionsComponentProps {
  actions: IAction[];
  secondaryActions?: IAction[];
  onClick?: (param?: string) => void;
  disableAfterClick?: boolean;
  smallIcons?: boolean;
  moveMenuUpwards?: boolean;
  noSpaceBetweenButtons?: boolean;
  inColumnView?: boolean;
  transitView?: TransitTypeEnum;
  showDebugMenu?: boolean;
}

export const CustomTableActionsComponent: React.FC<CustomTableActionsComponentProps> = (props) => {
  const contextMenuRef = useRef(null);
  const [actions, setActions] = useState([]);
  const [secondaryActions, setSecondaryActions] = useState([]);
  const { config } = useConfig();

  useEffect(() => {
    setActions(props.actions);
  }, [props.actions]);

  useEffect(() => {
    setSecondaryActions(props.secondaryActions);
  }, [props.secondaryActions]);

  const setDisableAction = (action: IAction, disabled?: boolean, isPrimary = true) => {
    const updatedActions = (isPrimary ? actions : secondaryActions).map((act) => ({
      ...act,
      disabled: act === action ? disabled : act.disabled,
    }));
    if (isPrimary) {
      setActions(updatedActions);
    } else {
      setSecondaryActions(updatedActions);
    }
  };

  const generateButton = (action: IAction, key?: string, showButtonText = false, isPrimary = true) => {
    const shouldDisable = action.disableAfterClick !== undefined ? action.disableAfterClick : props.disableAfterClick;

    return (
      <button
        key={key}
        title={!showButtonText ? action.text : undefined}
        className={`svg-icon ${showButtonText ? 'with-text' : ''}`}
        onClick={() => {
          if (shouldDisable) {
            setDisableAction(action, true, isPrimary);
          }
          action.onClick();
          if (!action.preventDefaultClick && props.onClick) {
            props.onClick();
          }
        }}
        disabled={!!action.disabled}
      >
        <Style.IconContainer src={action.icon} alt={action.text.toLowerCase().split(' ').join('-')} />
        {showButtonText && <div>{action.text}</div>}
      </button>
    );
  };

  return (
    <div>
      <Style.Container noSpaceBetweenButtons={props.noSpaceBetweenButtons} smallIcons={props.smallIcons}>
        {actions
          ?.filter((action) => [true, undefined].includes(action.if))
          .map((action, index) => generateButton(action, `primary-${index}-${action}`, false))}

        {secondaryActions?.length > 0 && (
          <Menu
            menuClassName={`contextual-menu ${props.moveMenuUpwards ? 'move-it-up' : ''} ${
              props.inColumnView ? 'column-view' : ''
            }`.trim()}
            align="center"
            direction="left"
            overflow={'auto'}
            position="anchor"
            arrow
            viewScroll="auto"
            menuButton={
              <button className="svg-icon" ref={contextMenuRef}>
                <Style.IconContainer src={contextualIcon} alt={'contextual'} />
              </button>
            }
            onMenuChange={(ev) =>
              contextMenuRef.current?.closest('.rdt_TableRow')?.classList[ev.open ? 'add' : 'remove']('menu-open')
            }
            transition
          >
            {secondaryActions
              ?.filter((action) => [true, undefined].includes(action.if))
              .map((action, index) => (
                <MenuItem key={`secondary-${index}-${action}`}>
                  {generateButton(action, undefined, true, false)}
                </MenuItem>
              ))}
          </Menu>
        )}

        {!config?.IS_PROD && props.showDebugMenu && (
          <Menu
            menuClassName={`transit-menu`}
            align="center"
            direction="left"
            overflow={'auto'}
            position="anchor"
            arrow
            viewScroll="auto"
            menuButton={
              <button
                className="svg-icon"
                ref={contextMenuRef}
                style={{ filter: 'invert(0.5) sepia(1) saturate(5) hue-rotate(180deg)' }}
              >
                <IconContainer src={contextualIcon} alt={'contextual'} />
              </button>
            }
            onMenuChange={(ev) =>
              contextMenuRef.current?.closest('.rdt_TableRow')?.classList[ev.open ? 'add' : 'remove']('menu-open')
            }
            transition
          >
            {(props.transitView === TransitTypeEnum.DEPARTURE
              ? [
                  { type: 'CC045C', label: 'CC045C - Finished' },
                  { type: 'CC009C', label: 'CC009C - Invalidation decision' },
                  { type: 'CC060C', label: 'CC060C - In control' },
                  { type: 'CC019C', label: 'CC019C - Discrepancies' },
                  { type: 'CC140C', label: 'CC140C - Non-arrival request info' },
                ]
              : [
                  { type: 'CC043C', label: `CC043C - Unload permission` },
                  { type: 'CC025C', label: `CC025C - Goods released` },
                ]
            ).map(({ type, label }) => (
              <MenuItem key={type}>
                <button
                  className="svg-icon with-text"
                  onClick={() => props.onClick(`generateNCTSResponse-${type}`)}
                  style={{ filter: 'invert(0.5) sepia(1) saturate(5) hue-rotate(180deg)' }}
                >
                  <IconContainer src={downloadIcon} alt={'simulate idms response for testing purpose'} />
                  <div>{label}</div>
                </button>
              </MenuItem>
            ))}
          </Menu>
        )}
      </Style.Container>
    </div>
  );
};
