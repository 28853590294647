/* eslint-disable no-loop-func */
/* eslint-disable no-restricted-syntax */
/* eslint-disable max-statements */
/* eslint-disable complexity */
import { TransitDocumentsEnum, TransitStatus } from '@e-origin/shared';
import { FormikProps } from 'formik';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectTransit } from '../../../../../../../../stores/transitsSlice';

import { TransitDocumentsComponent } from '../../../../../../transit-documents-component';
import { HouseItemFormInitializerReturn } from '../house-form-initializer';

interface HouseDocumentsProps {
  form: FormikProps<HouseItemFormInitializerReturn>;
  disabled: boolean;
}

export interface DocumentItemProps {
  type: TransitDocumentsEnum;
  label?: string;
  code: string;
  referenceNumber?: string;
  dateOfValidity?: string;
  dataSource: any;
  dataSourceIndex: number;
}

export const HouseDocuments: React.FC<HouseDocumentsProps> = (props) => {
  const handleDocumentDelete = (documentType: any, documentIndex: number) => {
    props.form.setFieldValue(`documents.${documentType}`, [
      ...(props.form.values.documents[documentType] || []).filter((_, idx) => idx !== documentIndex),
    ]);
  };

  const transit = useSelector(selectTransit);

  const handleSavingDocument = (savedDocument: any) => {
    if (savedDocument.docIndex >= 0) {
      const updatedDocuments = props.form.values.documents[savedDocument.type].map((doc, idx) => {
        if (idx === savedDocument.docIndex) {
          return savedDocument.data;
        }
        return doc;
      });
      props.form.setFieldValue(`documents.${savedDocument.type}`, updatedDocuments);
    } else {
      props.form.setFieldValue(`documents.${savedDocument.type}`, [
        ...(props.form.values.documents[savedDocument.type] || []),
        savedDocument.data,
      ]);
    }
  };

  const previewDocuments = useMemo(
    () => ({
      PreviousDocument: transit.Consignment?.PreviousDocument?.length ? transit.Consignment.PreviousDocument : [],
      SupportingDocument: transit.Consignment?.SupportingDocument?.length ? transit.Consignment.SupportingDocument : [],
      TransportDocument: transit.Consignment?.TransportDocument?.length ? transit.Consignment.TransportDocument : [],
      AdditionalReference: transit.Consignment?.AdditionalReference.length
        ? transit.Consignment.AdditionalReference
        : [],
      AdditionalInformation: transit.Consignment?.AdditionalInformation.length
        ? transit.Consignment.AdditionalInformation
        : [],
    }),
    [transit.Consignment],
  );

  return (
    <TransitDocumentsComponent
      previewDocuments={previewDocuments}
      documents={props.form.values.documents}
      onDelete={handleDocumentDelete}
      onSave={handleSavingDocument}
      disabledDocuments={
        transit.generalInfo.status === TransitStatus.UNLOAD_PERMISSION
          ? [TransitDocumentsEnum.PREVIOUS_DOCUMENT, TransitDocumentsEnum.ADDITIONAL_INFORMATION]
          : []
      }
      disabled={transit.generalInfo.status !== TransitStatus.UNLOAD_PERMISSION && props.disabled}
    />
  );
};
