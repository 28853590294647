import { Customer, Declarant, DeclarationDocumentsEnum } from '@e-origin/shared';

/* eslint-disable complexity */
export const customerDetailsFormValuesBuilder = (
  isCreate: boolean,
  generalDeclarant: Declarant,
  customerDetails: Customer,
) => {
  const {
    declarant: customerDetailsDeclarant,
    name: customerDetailsName,
    payments: customerDetailsPayments,
    representative: customerDetailsRepresentative,
    evaluationMethods: customerDetailsEvaluationMethods,
  } = customerDetails;

  const isCreateInitialValues: Customer = {
    name: '',
    declarant: {
      name: generalDeclarant.name || '',
      identificationNumber: generalDeclarant.identificationNumber || '',
      address: {
        street: generalDeclarant.address?.street || '',
        postcode: generalDeclarant.address?.postcode || '',
        city: generalDeclarant.address?.city || '',
        country: generalDeclarant.address?.country || '',
      },
      authorisedIdentity: generalDeclarant.authorisedIdentity || '',
      contactPerson: {
        name: generalDeclarant.contactPerson?.name || '',
        phoneNumber: generalDeclarant.contactPerson?.phoneNumber || '',
        email: generalDeclarant.contactPerson?.email || '',
      },
    },
    representative: {
      name: generalDeclarant.representative?.name,
      identificationNumber: generalDeclarant.representative?.identificationNumber || '',
      status: generalDeclarant.representative?.status || '',
      address: {
        street: generalDeclarant.representative?.address?.street,
        postcode: generalDeclarant.representative?.address?.postcode,
        city: generalDeclarant.representative?.address?.city,
        country: generalDeclarant.representative?.address?.country,
      },
    },
    payments: {
      methodOfPayment: null,
      deferredPayment: '',
      methodOfPaymentVAT: null,
      deferredPaymentVAT: '',
      additionalFiscalReference: [],
    },
    evaluationMethods: {
      evalMethodAIHSCode: {
        range: {
          from: 0,
          to: 0,
        },
        activateMethod: false,
      },
      evalMethodCalculator: {
        marketplaceFees: 0,
        fullfillmentCost: 0,
        storageCost: 0,
        otherCost: 0,
        activateMethod: false,
      },
      evalMethodWebValue: {
        minimumRate: 0,
        activateMethod: false,
      },
      evalMethodIMADBStatisticalValue: {
        minimumRate: 0,
        activateMethod: false,
      },
    },
    personProvidingGuarantee: {
      identificationNumber: '',
    },
    personPayingTheCustomDuty: {
      identificationNumber: '',
    },
    documents: {
      [DeclarationDocumentsEnum.GUARANTEE_DOCUMENTS]: [],
    },
    externalUsers: [],
    transitAuthorisations: [],
    transitGuarantees: [],
  } as unknown as Customer;

  const customerDetailsInitialValues: Customer = {
    name: customerDetailsName || '',
    declarant: {
      name: customerDetailsDeclarant?.name || '',
      identificationNumber: customerDetailsDeclarant?.identificationNumber || '',
      identificationNumberIDMS: customerDetailsDeclarant?.identificationNumberIDMS || '',
      useImporterEORI: customerDetailsDeclarant?.useImporterEORI || false,
      address: {
        street: customerDetailsDeclarant?.address?.street || '',
        postcode: customerDetailsDeclarant?.address?.postcode || '',
        city: customerDetailsDeclarant?.address?.city || '',
        country: customerDetailsDeclarant?.address?.country || '',
      },
      authorisedIdentity: customerDetailsDeclarant?.authorisedIdentity || '',
      contactPerson: {
        name: customerDetailsDeclarant?.contactPerson?.name || '',
        phoneNumber: customerDetailsDeclarant?.contactPerson?.phoneNumber || '',
        email: customerDetailsDeclarant?.contactPerson?.email || '',
      },
    },
    representative: {
      name: customerDetailsRepresentative?.name,
      identificationNumber: customerDetailsRepresentative?.identificationNumber || '',
      identificationNumberIDMS: customerDetailsRepresentative?.identificationNumberIDMS || '',
      status: customerDetailsRepresentative?.status || '',
      address: {
        street: customerDetailsRepresentative?.address?.street,
        postcode: customerDetailsRepresentative?.address?.postcode,
        city: customerDetailsRepresentative?.address?.city,
        country: customerDetailsRepresentative?.address?.country,
      },
    },
    payments: {
      methodOfPayment: customerDetailsPayments?.methodOfPayment || null,
      deferredPayment: customerDetailsPayments?.deferredPayment || '',
      methodOfPaymentVAT: customerDetailsPayments?.methodOfPaymentVAT || null,
      deferredPaymentVAT: customerDetailsPayments?.deferredPaymentVAT || '',
      additionalFiscalReference: customerDetailsPayments?.additionalFiscalReference || [],
    },
    evaluationMethods: {
      evalMethodAIHSCode: {
        range: {
          from: customerDetailsEvaluationMethods?.evalMethodAIHSCode?.range?.from || 0,
          to: customerDetailsEvaluationMethods?.evalMethodAIHSCode?.range?.to || 0,
        },
        activateMethod: customerDetailsEvaluationMethods?.evalMethodAIHSCode?.activateMethod || false,
      },
      evalMethodCalculator: {
        marketplaceFees: customerDetailsEvaluationMethods?.evalMethodCalculator?.marketplaceFees || 0,
        fullfillmentCost: customerDetailsEvaluationMethods?.evalMethodCalculator?.fullfillmentCost || 0,
        storageCost: customerDetailsEvaluationMethods?.evalMethodCalculator?.storageCost || 0,
        otherCost: customerDetailsEvaluationMethods?.evalMethodCalculator?.otherCost || 0,
        activateMethod: customerDetailsEvaluationMethods?.evalMethodCalculator?.activateMethod || false,
      },
      evalMethodWebValue: {
        minimumRate: customerDetailsEvaluationMethods?.evalMethodWebValue?.minimumRate || 0,
        activateMethod: customerDetailsEvaluationMethods?.evalMethodWebValue?.activateMethod || false,
      },
      evalMethodIMADBStatisticalValue: {
        minimumRate: customerDetailsEvaluationMethods?.evalMethodIMADBStatisticalValue?.minimumRate || 0,
        activateMethod: customerDetailsEvaluationMethods?.evalMethodIMADBStatisticalValue?.activateMethod || false,
      },
    },
    personProvidingGuarantee: {
      identificationNumber: customerDetails?.personProvidingGuarantee?.identificationNumber || '',
    },
    personPayingTheCustomDuty: {
      identificationNumber: customerDetails?.personPayingTheCustomDuty?.identificationNumber || '',
    },
    documents: {
      [DeclarationDocumentsEnum.GUARANTEE_DOCUMENTS]: customerDetails?.documents?.guaranteeDocuments || [],
    },
    externalUsers: customerDetails.externalUsers || [],
    transitAuthorisations: customerDetails.transitAuthorisations || [],
    transitGuarantees: customerDetails.transitGuarantees || [],
  } as unknown as Customer;

  return isCreate ? isCreateInitialValues : customerDetailsInitialValues;
};
