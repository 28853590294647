import { MultilingualCodeSystems } from '@e-origin/shared';
import { FormikProps } from 'formik';
import { Fragment } from 'react';
import { Button, Input, MultiLangCodes } from '../../../../components';
import { FormRow } from '../../../../styles/common';
import trashIcon from '../../../../assets/icons/trash-icon.svg';

import * as Style from './transit-guarantees.style';
import { TransitDetailsFormProps } from '../../transit-details.component';

interface TransitGuaranteesProps {
  form: FormikProps<TransitDetailsFormProps>;
  disabled: boolean;
}

export const TransitGuarantees: React.FC<TransitGuaranteesProps> = ({ form, disabled }) => {
  const guarantees = form.values.Guarantee;

  const handleAddGuarantee = () => {
    form.setFieldValue('Guarantee', [
      ...guarantees,
      {
        guaranteType: '',
        GuaranteeReference: [
          {
            accessCode: '',
            currency: '',
            GRN: '',
            amountToBeCovered: '',
          },
        ],
      },
    ]);
  };

  const handleRemoveGuarantee = (guaranteeIdx: number) => {
    const newGuarantees = guarantees.filter((_, idx) => idx !== guaranteeIdx);
    form.setFieldValue('Guarantee', newGuarantees);
  };

  const handleAddReference = (guaranteeIdx: number) => {
    const guarantee = guarantees[guaranteeIdx];
    const newReference = {
      accessCode: '',
      currency: '',
      GRN: '',
      amountToBeCovered: '',
    };
    guarantee.GuaranteeReference.push(newReference);
    form.setFieldValue('Guarantee', [...guarantees]);
  };

  const handleDeleteReference = (guaranteeIdx: number, referenceIdx: number) => {
    const guarantee = guarantees[guaranteeIdx];
    guarantee.GuaranteeReference = guarantee.GuaranteeReference.filter((_, idx) => idx !== referenceIdx);
    form.setFieldValue('Guarantee', [...guarantees]);
  };

  return (
    <>
      {guarantees.map((guarantee, guaranteeIdx) => (
        <Fragment key={`${guarantees.length}-${guaranteeIdx}`}>
          <FormRow>
            <MultiLangCodes
              name={`Guarantee[${guaranteeIdx}].guaranteeType`}
              placeholder="Guarantee Type"
              width={24}
              system={MultilingualCodeSystems.NCTS}
              value={guarantee.guaranteeType}
              onChange={form.handleChange}
              code="CL251"
              disabled={disabled}
            />
            {!disabled && (
              <Style.ButtonContainer>
                <Button type="button" onClick={() => handleRemoveGuarantee(guaranteeIdx)}>
                  <img src={trashIcon} alt={'delete'} />
                </Button>
              </Style.ButtonContainer>
            )}
          </FormRow>
          {guarantee.GuaranteeReference.map((reference, referenceIdx) => (
            <Fragment key={referenceIdx}>
              <FormRow>
                <Input
                  name={`Guarantee[${guaranteeIdx}].GuaranteeReference[${referenceIdx}].accessCode`}
                  placeholder={`Access Code ${referenceIdx + 1}`}
                  width={20}
                  widthUnit="%"
                  value={reference.accessCode}
                  onChange={form.handleChange}
                  disabled={disabled}
                />
                <MultiLangCodes
                  name={`Guarantee[${guaranteeIdx}].GuaranteeReference[${referenceIdx}].currency`}
                  placeholder={`Currency ${referenceIdx + 1}`}
                  width={24}
                  system={MultilingualCodeSystems.NCTS}
                  value={reference.currency}
                  onChange={form.handleChange}
                  code="CL048"
                  disabled={disabled}
                />
                <Input
                  name={`Guarantee[${guaranteeIdx}].GuaranteeReference[${referenceIdx}].GRN`}
                  placeholder={`GRN ${referenceIdx + 1}`}
                  width={20}
                  widthUnit="%"
                  value={reference.GRN}
                  onChange={form.handleChange}
                  disabled={disabled}
                />
                <Input
                  name={`Guarantee[${guaranteeIdx}].GuaranteeReference[${referenceIdx}].amountToBeCovered`}
                  placeholder={`AmountToBeCovered ${referenceIdx + 1}`}
                  width={20}
                  widthUnit="%"
                  value={reference.amountToBeCovered}
                  onChange={form.handleChange}
                  disabled={disabled}
                />
                {!disabled && referenceIdx === guarantee.GuaranteeReference.length - 1 && (
                  <Style.ButtonContainer>
                    <Button
                      style={{ fontSize: 30 }}
                      primary
                      type="button"
                      onClick={() => handleAddReference(guaranteeIdx)}
                    >
                      +
                    </Button>
                  </Style.ButtonContainer>
                )}
                {!disabled && referenceIdx < guarantee.GuaranteeReference.length - 1 && (
                  <Style.ButtonContainer>
                    <Button type="button" onClick={() => handleDeleteReference(guaranteeIdx, referenceIdx)}>
                      <img src={trashIcon} alt={'delete'} />
                    </Button>
                  </Style.ButtonContainer>
                )}
              </FormRow>
            </Fragment>
          ))}
          {guaranteeIdx < guarantees.length - 1 && <Style.Separator widthPercentage={100} />}
        </Fragment>
      ))}
      {!disabled && (
        <Style.ButtonContainer>
          <Button type="button" primary onClick={handleAddGuarantee} disabled={disabled}>
            Add Guarantee
          </Button>
        </Style.ButtonContainer>
      )}
    </>
  );
};
