/* eslint-disable no-console */
/* eslint-disable consistent-return */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
/* eslint-disable no-param-reassign */
import { toast } from 'react-toastify';

import { request } from '../utils';
import { AppThunk, RootState } from './index';

interface ISettingsState {
  clientBaseUrl: string;
  walletEnabled: boolean;
  highValuesIDMSEnabled: boolean;
  aesEnabled: boolean;
  reSendDelay: number;
  transitEnabled: boolean;
}

const initialState: ISettingsState = {
  clientBaseUrl: '',
  walletEnabled: false,
  aesEnabled: false,
  highValuesIDMSEnabled: false,
  reSendDelay: 0,
  transitEnabled: false,
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setSettings: (state, action: PayloadAction<ISettingsState>) => {
      const { clientBaseUrl, walletEnabled, highValuesIDMSEnabled, aesEnabled } = action.payload;
      state.clientBaseUrl = clientBaseUrl;
      state.walletEnabled = walletEnabled;
      state.highValuesIDMSEnabled = highValuesIDMSEnabled;
      state.aesEnabled = aesEnabled;
      state.reSendDelay = action.payload.reSendDelay;
      state.transitEnabled = action.payload.transitEnabled;
    },
  },
});

export const { setSettings } = settingsSlice.actions;

export const selectSettings = (state: RootState) => state.settings;

export const fetchSettings = (): AppThunk<Promise<void>> => async (dispatch) => {
  try {
    const data = await request({
      path: `settings`,
      method: 'GET',
      authenticate: false,
    });
    dispatch(setSettings(data));
    return data;
  } catch (error) {
    console.error(error);
    toast.error('Error fetching the settings!');
  }
};

export const retrieveCodes = async (
  code: string | string[],
  search: string,
  locale = 'en',
  system: string = undefined,
) => {
  try {
    const { codes } = await request({
      path: `settings/get-codes`,
      method: 'POST',
      authenticate: true,
      dataObject: {
        locale: locale.toLowerCase(),
        code,
        search,
        system,
      },
    });

    return codes;
  } catch (error) {
    console.error(error);
  }
};

export default settingsSlice.reducer;
