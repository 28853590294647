import styled from 'styled-components';
import tw from 'twin.macro';

export const Container = styled.div`
  ${tw`
    flex
    flex-col
  `}
  padding-right: 0%;
`;

export const CustomerDetailsFormContainer = styled.form`
  ${tw`
    flex
    flex-row
    items-center
    justify-between
    pr-0
  `}
`;

export const SubmitSection = styled.div`
  margin-bottom: 55px;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  p {
    font-size: 15px;
    font-weight: 500;
  }
`;

export const Checkbox = styled.input`
  accent-color: #1870a3;
  height: 20px;
  margin-right: 15px;
  width: 20px;
`;
