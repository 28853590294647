import { TransitControlResult } from '@e-origin/shared';

import * as Style from './control-result-details.style';

interface IControlResultProps {
  controlResult: TransitControlResult;
}

export const ControlResultDetails = ({ controlResult: item }: IControlResultProps) => {
  return (
    <>
      <Style.Section key={`control-result`} topPadding={10} paddingBottom={20}>
        <Style.SectionTitle>Control result</Style.SectionTitle>
        <Style.Row>
          <div>
            <label>Date</label>
            <p>{item.date}</p>
          </div>
          <div>
            <label>Control code</label>
            <p>{item.code}</p>
          </div>
        </Style.Row>
        <Style.Row>
          <div>
            <label>Remarks</label>
            <p>{item.remarks}</p>
          </div>
        </Style.Row>
        <Style.Row>
          <div>
            <label>Text</label>
            <p>{item.text}</p>
          </div>
        </Style.Row>
        <Style.Row>
          <div>
            <label>Controlled by</label>
            <p>{item.controlledBy}</p>
          </div>
        </Style.Row>
      </Style.Section>
    </>
  );
};
