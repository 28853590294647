/* eslint-disable max-statements */
import { useFormik } from 'formik';
import { useState } from 'react';

import { Button, Spinner, Tab, Tabs } from '../../../../../../../components';

import ModalStyle from '../../../../../../../styles/modal';
import { HouseItemFormInitializerReturn, houseFormIntializer } from './house-form-initializer';

import * as Style from './house-form.style';
import { HouseBatches, HouseConsignmentData, HouseDocuments, HouseStakeholders, HouseTransport } from './tabs';
import { TransitHouseConsignment } from '@e-origin/shared';
interface HouseProps {
  houseConsignment?: TransitHouseConsignment;
  onHide: () => void;
  onSave: (houseConsignment: TransitHouseConsignment) => void;
  disabled: boolean;
}

export const HouseForm: React.FC<HouseProps> = ({ houseConsignment, onHide, onSave, disabled }) => {
  const [isSaving, setIsSaving] = useState(false);

  const form = useFormik<HouseItemFormInitializerReturn>({
    initialValues: houseFormIntializer(houseConsignment),
    onSubmit: async (values) => {
      setIsSaving(true);

      const data = {
        ...values,
        ...values.documents,
        documents: undefined,
      } as TransitHouseConsignment;

      onSave(data);
      setIsSaving(false);
    },
  });

  const handleSubmit = async () => {
    if (!isSaving) {
      await form.submitForm();
    }
  };

  return (
    <Style.Container>
      <Tabs style={{ margin: '0 15px 15px 15px' }} contentStyle={{ padding: '0 50px', overflow: 'auto' }}>
        <Tab label="House Consignment">
          <HouseConsignmentData form={form} disabled={disabled} />
        </Tab>
        <Tab label="Stakeholders">
          <HouseStakeholders form={form} disabled={disabled} />
        </Tab>
        <Tab label="Transport">
          <HouseTransport form={form} disabled={disabled} />
        </Tab>
        <Tab label="Documents">
          <HouseDocuments form={form} disabled={disabled} />
        </Tab>
        <Tab label="Batches">
          <HouseBatches form={form} disabled={disabled} />
        </Tab>
      </Tabs>
      <ModalStyle.Footer>
        <Button type="button" onClick={onHide}>
          Cancel
        </Button>
        {!disabled && (
          <Button primary onClick={handleSubmit}>
            {isSaving ? (
              <>
                <Spinner isContrast />
                Saving
              </>
            ) : (
              'Save'
            )}
          </Button>
        )}
      </ModalStyle.Footer>
    </Style.Container>
  );
};
