import styled from 'styled-components';
import tw from 'twin.macro';

export const Header = styled.div`
  ${tw`
    flex
    flex-col
    flex-shrink-0
  `};
  padding: 0 40px;
`;

export const Summary = styled.div`
  ${tw`
    flex
    flex-shrink-0
  `}
  margin: 30px 0;

  div {
    margin-right: 7px;

    label {
      font-size: 13px;
      letter-spacing: 0.26px;
      line-height: 15px;
      margin: 0;
      padding: 0;
    }

    p {
      font-weight: bold;
      font-size: 24px;
      margin: 0;
      padding: 0;
      letter-spacing: 0.4px;
      line-height: 24px;

      &.status {
        color: ${({ theme }) => theme.colors.primary};
      }
    }
  }
`;

export const BackButton = styled.button`
  ${tw`
    flex
    items-center
    outline-none
    focus:outline-none
    font-bold
  `}
  font-size:18px;
  letter-spacing: 0;
  line-height: 21px;
  width: fit-content;

  img {
    margin-right: 5px;
  }
`;

export const CommonInputs = styled.div`
  ${tw`
    flex
    flex-col
    gap-y-4
  `}
`;

export const CommonInputsRow = styled.div`
  ${tw`
    flex
    items-center
  `}

  & > div {
    margin-left: 15px;

    &:first-child {
      margin-left: 0;
    }
  }
`;
