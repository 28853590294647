/* eslint-disable complexity */
/* eslint-disable no-restricted-syntax */
/* eslint-disable default-case */
import * as Style from './transit-details-house.style';
import { FormikProps } from 'formik';
import { HouseDetails } from './house-details/house-details.component';
import { GoodsItems } from './goods-items';
import { useLocation } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { TransitDetailsFormProps } from '../../transit-details.component';

interface TransitDetailsHouseProps {
  form: FormikProps<TransitDetailsFormProps>;
  editDisabled: boolean;
}

enum TransitDetailsContext {
  HOUSE = 'HOUSE',
  GOODS = 'GOODS',
}

export const TransitDetailsHouse: React.FC<TransitDetailsHouseProps> = ({ form, editDisabled }) => {
  const routeLocation = useLocation();
  const queryParams = useRef<URLSearchParams>(new URLSearchParams(routeLocation.search));
  const [context, setContext] = useState<TransitDetailsContext>(TransitDetailsContext.HOUSE);

  useEffect(() => {
    queryParams.current = new URLSearchParams(routeLocation.search);

    if (queryParams.current.get('context')) {
      setContext(queryParams.current.get('context') as TransitDetailsContext);
    } else {
      setContext(TransitDetailsContext.HOUSE);
    }
  }, [routeLocation]);

  return (
    <Style.Container>
      {[TransitDetailsContext.HOUSE, null].includes(context) && (
        <HouseDetails form={form} editDisabled={editDisabled} />
      )}
      {context === TransitDetailsContext.GOODS && <GoodsItems form={form} editDisabled={editDisabled} />}
    </Style.Container>
  );
};
