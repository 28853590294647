import styled from 'styled-components';
import tw from 'twin.macro';

/* eslint-disable no-nested-ternary */
import { InputErrorStatus } from '../../enums';

interface ILabelProps {
  placeholderOnTop?: boolean;
  disabled?: boolean;
  compact?: boolean;
}

interface IContainerProps {
  width?: number;
  invalid?: boolean;
  widthUnit?: 'px' | '%' | 'em' | 'rem';
  disabled?: boolean;
  compact?: boolean;
}

export const Container = styled.div<IContainerProps>`
  ${tw`
    flex
    relative
    items-center
  `};
  height: ${({ compact }) => (compact ? '50px' : '65px')};
  padding: 10px 15px;
  border: 1px solid ${({ theme, invalid }) => (invalid ? theme.colors.input.borderInvalid : theme.colors.input.border)};
  border-radius: 5px;
  width: ${({ width, widthUnit }) => (width ? `${width}${widthUnit || 'px'}` : 'auto')};
  background-color: ${({ theme, invalid, disabled }) =>
    invalid ? theme.colors.input.bgInvalid : disabled ? theme.colors.input.bgDisabled : 'transparent'};
  input {
    ${tw`
      flex-1
      h-full
      outline-none
      focus:outline-none
      w-full
    `};
    font-size: 16px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.input.placeholder};
    line-height: 19px;
    background: transparent;
    opacity: ${({ disabled }) => (disabled ? 0.7 : 1)};
    margin-top: 10px;

    // hide arrow from input number
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
    }

    // [Firefox] hide arrow from input number
    &[type='number'] {
      -moz-appearance: textfield;
    }
  }
`;

export const Label = styled.label<ILabelProps>`
  cursor: text;
  color: ${({ theme }) => theme.colors.input.placeholder};
  transition: all 0.2s ease;
  position: absolute;
  font-size: ${({ placeholderOnTop }) => (placeholderOnTop ? '13px' : '16px')};
  top: ${({ placeholderOnTop }) => (placeholderOnTop ? '3px' : 'initial')};
  line-height: ${({ placeholderOnTop }) => (placeholderOnTop ? '15px' : '19px')};
  opacity: ${({ disabled }) => (disabled ? 0.7 : 1)};
`;

interface IMessageContainer {
  status: string;
}

export const MessageContainer = styled.div<IMessageContainer>`
  position: absolute;
  border-radius: 20px;
  background-color: ${({ status }) => (status === InputErrorStatus.SUCCESS ? '#29AA47' : '#FF6C0D')};
  color: #ffffff;
  padding: 5px 7px;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.22px;
  top: 80%;
  right: -15px;
  display: flex;
  align-items: center;
`;

interface IInputImageProps {
  isButtonDisabled?: boolean;
  webScrapingDone?: boolean;
  onPictureClick?: () => void;
}

export const InputImage = styled.img<IInputImageProps>`
  ${tw`
    object-contain
  `};
  opacity: ${({ isButtonDisabled }) => (isButtonDisabled ? 0.2 : 1)};
  cursor: ${({ webScrapingDone }) => (webScrapingDone ? 'pointer' : 'default !important')};
  width: 20px;
  height: 20px;

  :hover {
    cursor: pointer;
  }
`;
