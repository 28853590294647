/* eslint-disable max-lines */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-statements */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
import { FC, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { container, TYPES } from '@e-origin/ioc';
/* eslint-disable complexity */
import {
  Declaration,
  DeclarationGoods,
  IHSCodeRecommendation,
  IRiskAnalysisHSCodeNewValues,
  ISaveNotes,
} from '@e-origin/shared';

import Icons from '../../../../../../assets';
import { Button, HsCodeDescriptionField, Input, Spinner } from '../../../../../../components';
import { InputErrorStatus } from '../../../../../../enums';
import { RiskAnalysisService } from '../../../../../../services';
import { selectDeclarant } from '../../../../../../stores/declarantSlice';
import { FormRow } from '../../../../../../styles/common';
import { RiskAnalysisNotesModal } from '../../modals/risk-analysis-notes-modal/risk-analysis-notes-modal.component';
import { ValidateAllStateEnum } from '../../risk-analysis-modal.component';
import {
  Checkbox,
  CheckboxContainer,
  RiskAnalysisSection,
  RiskAnalysisSectionContent,
  RiskAnalysisSectionHeader,
  WebSite,
} from '../../risk-analysis-modal.styles';
import { HSCodeRecommendations } from './components/hs-code-recommendations/hs-code-recommendations';
import { ManageSupportingDocuments } from './components/manage-supporting-documents/manage-supporting-documents';
import {
  HsCodeDescriptionInfo,
  HsCodeDescriptionInfoItem,
  HsCodeDescriptionInfoItemHeader,
  HsCodeDescriptionInfoItemTitle,
  RiskAnalysisHSCodeInfo,
  RiskAnalysisHSCodeInputWrapper,
  RiskAnalysisHsCodeSectionTitle,
  RiskAnalysisHSCodeWebsiteSection,
} from './risk-analysis-hs-code.styles';

interface IRiskAnalysisHSCodeProps {
  declaration: Declaration;
  goodDetailsOriginal: DeclarationGoods;
  goodDetails: DeclarationGoods;
  update: (obj: IRiskAnalysisHSCodeNewValues) => void;
  validate: ValidateAllStateEnum;
}

const VALIDATE_HS_CODE = 'Validate the HS Code';
const INVOICE_HS_CODE = 'Invoice HS Code';
const TARBEL_HS_CODE_WEBSITE = 'Tarbel HS Code website';
const NEW_HS_CODE_PROPOSITION = 'New HS Code proposition';

export const RiskAnalysisHSCode: FC<IRiskAnalysisHSCodeProps> = ({
  goodDetails,
  goodDetailsOriginal,
  declaration,
  update,
  validate,
}) => {
  const riskAnalysisService: RiskAnalysisService = container.get(TYPES.RiskAnalysisService);

  const declarant = useSelector(selectDeclarant);

  const currentDate = new Date().toISOString().slice(0, 10).replace(/-/g, '');
  const hsCodeRemoteURL = `https://eservices.minfin.fgov.be/extTariffBrowser/Measure?cnCode=${goodDetails.commodityCode.hsCode}&country=29422&date=${currentDate}&lang=${declarant.language}&page=1&trade=0`;

  const euroStatURL = 'https://eurostat.prod.3ceonline.com/';

  const taricDescriptionList = goodDetails.evalMethodAiHsCode?.taricDescriptionList || [];
  const taricDescriptionListException = goodDetails.evalMethodAiHsCode?.taricDescriptionListException || [];
  const taricOtherMeasures = goodDetails.evalMethodAiHsCode?.taricOtherMeasures || [];

  const [validatedHSCodeCheck, setValidatedHSCodeCheck] = useState(
    !!goodDetails.evalMethodAiHsCode?.validatedHSCodeCheck,
  );
  const validatedHSCodeCheckDisabled = riskAnalysisService.evalMethodAiHsCodeCheckDisabled(goodDetailsOriginal);
  const invoiceHSCode = goodDetails.commodityCode.hsCode || '';
  const [newHsCodeDescription, setNewHsCodeDescription] = useState(
    goodDetails.evalMethodAiHsCode?.suggestedHsCode || '',
  );
  const suggestedHsCodeChanged = useRef<boolean>(false);

  const [nowMadbValue, setNowMadbValue] = useState(
    goodDetails.evalMethodStatisticalValue?.suggestedLowest?.madbAmountPerKg,
  );
  const [newHsCodeDescriptionDutyRate, setNewHsCodeDescriptionDutyRate] = useState<string>(
    goodDetails.evalMethodAiHsCode.suggestedTaricRate,
  );
  const [newTaricMadbLoading, setNewTaricMadbLoading] = useState(false);

  const [hsCodeRecommendations, setHsCodeRecommendations] = useState<IHSCodeRecommendation[]>([]);

  const [showNotes, setShowNotes] = useState(false);

  const [showSupportingDocuments, setShowSupportingDocuments] = useState(false);

  const [annotationNote, setAnnotationNote] = useState('');
  const [annotationCustomsDecision, setAnnotationCustomsDecision] = useState(false);

  useEffect(() => {
    const predictions = (goodDetails.evalMethodAiHsCode.predictedDistances || []).map((prediction) => ({
      hsCode: prediction[0],
      occurance: prediction[2],
      probability: prediction[3],
      description: prediction[1],
    }));
    setHsCodeRecommendations(predictions);
    setAnnotationNote(goodDetailsOriginal.evalMethodAiHsCode.annotationNote || '');
    setAnnotationCustomsDecision(!!goodDetailsOriginal.evalMethodAiHsCode.annotationCustomsDecision);
  }, [goodDetailsOriginal]);

  useEffect(() => {
    setNewHsCodeDescription(goodDetails.evalMethodAiHsCode?.suggestedHsCode || '');
    setValidatedHSCodeCheck(!!goodDetails.evalMethodAiHsCode?.validatedHSCodeCheck);
  }, [goodDetails]);

  useEffect(() => {
    if (validate === null || validate === ValidateAllStateEnum.SOME_SELECTED) {
      return;
    }
    if (validatedHSCodeCheck || validate === ValidateAllStateEnum.ALL_SELECTED) {
      setNewHsCodeDescription('');
    }
    setValidatedHSCodeCheck(validate === ValidateAllStateEnum.ALL_SELECTED);
  }, [validate]);

  useEffect(() => {
    suggestedHsCodeChanged.current =
      newHsCodeDescription !== undefined && newHsCodeDescription !== goodDetails.evalMethodAiHsCode.suggestedHsCode;
  }, [newHsCodeDescription]);

  useEffect(() => {
    update({
      newHsCodeDescription,
      validatedHSCodeCheck,
      validatedHSCodeCheckDisabled,
      nowMadbValue,
      annotationNote,
      annotationCustomsDecision,
      newHsCodeDescriptionDutyRate,
    });
  }, [
    newHsCodeDescription,
    validatedHSCodeCheck,
    nowMadbValue,
    annotationNote,
    annotationCustomsDecision,
    newHsCodeDescriptionDutyRate,
  ]);

  const displayRateMessage = () => {
    if (goodDetails.evalMethodAiHsCode?.suggestedHsCode) {
      return {
        status: InputErrorStatus.SUCCESS,
        text: 'New HS Code',
      };
    }
    const status =
      !goodDetails.evalMethodAiHsCode?.hsCodeOk &&
      goodDetails.evalMethodAiHsCode?.hsCodeFound !== false &&
      goodDetails.evalMethodAiHsCode?.taricDutyNotFound !== true &&
      goodDetails.evalMethodAiHsCode?.taricRatioToCheck !== true &&
      goodDetails.evalMethodAiHsCode?.taricWithRestrictions !== true &&
      goodDetails.evalMethodAiHsCode?.taricOriginException !== true
        ? InputErrorStatus.SUCCESS
        : InputErrorStatus.ERROR;

    let text = '';
    if (goodDetails.evalMethodAiHsCode?.hsCodeFound === false) {
      text = 'NOT FOUND';
    } else if (goodDetails.evalMethodAiHsCode?.taricDutyNotFound === true) {
      text = 'DUTY NOT FOUND';
    } else if (goodDetails.evalMethodAiHsCode?.taricWithRestrictions === true) {
      text = 'CERTIFICATES REQUIRED';
    } else if (goodDetails.evalMethodAiHsCode?.taricRatioToCheck === true) {
      text = 'RATIO TO CHECK';
    } else if (goodDetails.evalMethodAiHsCode?.taricOriginException === true) {
      text = 'ORIGIN EXCEPTION';
    }
    if (text) {
      return { status, text };
    }
    return goodDetails.evalMethodAiHsCode?.hsCodeOk
      ? { status: InputErrorStatus.SUCCESS, text: `${invoiceHSCode.slice(0, 6)} reliable`, hideIcon: true }
      : { status: InputErrorStatus.ERROR, text: `${invoiceHSCode.slice(0, 6)} not reliable`, hideIcon: true };
  };

  const setNewHsCodeDescriptionDutyRateAndMadb = async () => {
    setNewTaricMadbLoading(true);
    try {
      const { dutyRate, madbValue } = await riskAnalysisService.retrieveDutyRateAndMadbValue(newHsCodeDescription);
      suggestedHsCodeChanged.current = false;
      setNewHsCodeDescriptionDutyRate(dutyRate);
      setNowMadbValue(madbValue[0]?.value);
    } catch (err) {
      // pass
    }
    setNewTaricMadbLoading(false);
  };

  const toggleManageSupportingDocumentsModal = () => {
    setShowSupportingDocuments(!showSupportingDocuments);
  };

  return (
    <>
      {showNotes && (
        <RiskAnalysisNotesModal
          onHide={(res: ISaveNotes) => {
            setShowNotes(!showNotes);
            if (res) {
              setAnnotationCustomsDecision(res?.customsDecisionChecked);
              setAnnotationNote(res?.notes);
            }
          }}
          declaration={declaration}
          goodDetails={goodDetails}
        ></RiskAnalysisNotesModal>
      )}
      {showSupportingDocuments && (
        <ManageSupportingDocuments
          declaration={declaration}
          taricOtherMeasures={goodDetails.evalMethodAiHsCode.taricOtherMeasures}
          documents={declaration.documents.supportingDocuments}
          onHide={toggleManageSupportingDocumentsModal}
        ></ManageSupportingDocuments>
      )}
      <RiskAnalysisSection>
        <RiskAnalysisSectionHeader>
          <RiskAnalysisHsCodeSectionTitle>
            <button onClick={() => setShowNotes(true)}>
              <img src={Icons.NotesIcon} />
            </button>
            <h4>HS Code</h4>
          </RiskAnalysisHsCodeSectionTitle>
          <CheckboxContainer>
            <Checkbox
              name={'validatedHSCodeCheck'}
              checked={validatedHSCodeCheck}
              onChange={() => {
                setNewHsCodeDescription('');
                setValidatedHSCodeCheck(!validatedHSCodeCheck);
              }}
              type={'checkbox'}
            />
            <p style={{ color: '#555454' }}>{VALIDATE_HS_CODE}</p>
          </CheckboxContainer>
        </RiskAnalysisSectionHeader>
        <RiskAnalysisSectionContent>
          <FormRow>
            <RiskAnalysisHSCodeInputWrapper>
              <Input
                defaultBehaviour={true}
                compact={true}
                placeholder={INVOICE_HS_CODE}
                value={invoiceHSCode}
                width={100}
                widthUnit="%"
                disabled={true}
                message={displayRateMessage()}
              />
              {(goodDetails.evalMethodAiHsCode?.taricDuty || [])[0] && (
                <RiskAnalysisHSCodeInfo isLink={false}>
                  {(goodDetails.evalMethodAiHsCode?.taricDuty || [])[0]} Third country duty
                </RiskAnalysisHSCodeInfo>
              )}
            </RiskAnalysisHSCodeInputWrapper>
            {!validatedHSCodeCheck && (
              <RiskAnalysisHSCodeInputWrapper>
                <Input
                  defaultBehaviour={true}
                  compact={true}
                  name="newHsCodeDescription"
                  placeholder={NEW_HS_CODE_PROPOSITION}
                  value={newHsCodeDescription}
                  onChange={(event) => {
                    setNewHsCodeDescriptionDutyRate(null);
                    setNowMadbValue(
                      event.target.value
                        ? goodDetails.evalMethodStatisticalValue?.suggestedLowest?.madbAmountPerKg
                        : null,
                    );
                    setNewHsCodeDescription(event.target.value);
                  }}
                  width={100}
                  widthUnit="%"
                  type={'number'}
                />
                {newHsCodeDescription && (
                  <RiskAnalysisHSCodeInfo
                    isLink={
                      !goodDetails.evalMethodStatisticalValue?.suggestedLowest?.madbAmountPerKg &&
                      suggestedHsCodeChanged.current
                    }
                    style={{
                      cursor:
                        newHsCodeDescriptionDutyRate &&
                        !goodDetails.evalMethodStatisticalValue?.suggestedLowest?.madbAmountPerKg
                          ? 'grab'
                          : 'pointer',
                    }}
                    onClick={async () =>
                      !newHsCodeDescriptionDutyRate &&
                      !goodDetails.evalMethodStatisticalValue?.suggestedLowest?.madbAmountPerKg &&
                      setNewHsCodeDescriptionDutyRateAndMadb()
                    }
                  >
                    {!newHsCodeDescriptionDutyRate &&
                    !goodDetails.evalMethodStatisticalValue?.suggestedLowest?.madbAmountPerKg &&
                    !suggestedHsCodeChanged.current
                      ? `Not found`
                      : newHsCodeDescriptionDutyRate
                        ? `${newHsCodeDescriptionDutyRate} Third country duty`
                        : 'View third country duty & update IMADB'}
                    {newTaricMadbLoading && <Spinner />}
                  </RiskAnalysisHSCodeInfo>
                )}
              </RiskAnalysisHSCodeInputWrapper>
            )}
          </FormRow>
          <HsCodeDescriptionInfo>
            <HsCodeDescriptionInfoItem>
              <HsCodeDescriptionInfoItemHeader>
                <HsCodeDescriptionInfoItemTitle>Invoice HS Code Description:</HsCodeDescriptionInfoItemTitle>
                <Button
                  onClick={toggleManageSupportingDocumentsModal}
                  type="button"
                  primary
                  style={{
                    height: 'auto',
                    padding: '0 15px',
                    borderRadius: '5px',
                    fontWeight: '400',
                  }}
                >
                  Selected Codes
                </Button>
              </HsCodeDescriptionInfoItemHeader>
              <HsCodeDescriptionField
                taricDescriptionList={taricDescriptionList}
                taricDescriptionListException={taricDescriptionListException}
                taricOtherMeasures={taricOtherMeasures}
              />
            </HsCodeDescriptionInfoItem>

            <HSCodeRecommendations values={hsCodeRecommendations}></HSCodeRecommendations>
          </HsCodeDescriptionInfo>
          <RiskAnalysisHSCodeWebsiteSection>
            <WebSite href={hsCodeRemoteURL} target={'_blank'}>
              {TARBEL_HS_CODE_WEBSITE}
            </WebSite>
          </RiskAnalysisHSCodeWebsiteSection>
        </RiskAnalysisSectionContent>
      </RiskAnalysisSection>
    </>
  );
};
