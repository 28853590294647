/* eslint-disable max-statements */
import { useFormik } from 'formik';
import { FC } from 'react';
import { toast } from 'react-toastify';

import { Button, Dropdown, Input, Modal } from '../../../../components';
import { FormContainer, FormRow, FormSection } from '../../../../styles/common';
import { authorisationTypes } from '../authorisationTypes';

interface ITransitAuthFormModalProps {
  transitAuthEdit: { type: string; referenceNumber: string } | null;
  show: boolean;
  onHide(): void;
  onSave(updatedTransitAuthReference: { type: string; referenceNumber: string }): void;
}

export const TransitAuthFormModal: FC<ITransitAuthFormModalProps> = (props) => {
  const { transitAuthEdit, show, onHide, onSave } = props;

  const formik = useFormik<{ type: string; referenceNumber: string }>({
    initialValues: {
      type: transitAuthEdit?.type || '',
      referenceNumber: transitAuthEdit?.referenceNumber || '',
    },
    onSubmit: (values) => {
      const { type, referenceNumber } = values;
      if (!type || !referenceNumber) {
        toast.error('Type or reference number are missing!');
        return;
      }

      const alphanumericRegex = /^[0-9a-zA-Z]*$/;
      if (!alphanumericRegex.test(referenceNumber)) {
        toast.error('Invalid reference number!');
        return;
      }

      onSave(values);
      onHide();
    },
    enableReinitialize: false,
  });

  const startFormSubmit = async () => {
    await formik.submitForm();
  };

  const typeValue = authorisationTypes.find((type) => {
    return type.value === formik.values.type;
  });

  const modalTitle = transitAuthEdit ? 'Edit transit authorization' : 'Create transit authorization';
  const saveButtonTitle = transitAuthEdit ? 'Update' : 'Add';

  return (
    <Modal
      title={modalTitle}
      show={show}
      onHide={() => onHide()}
      buttons={[
        <Button type="button" primary key="save-modal-btn" onClick={startFormSubmit}>
          {saveButtonTitle}
        </Button>,
      ]}
      buttonsJustifyAlign="center"
    >
      <FormContainer onSubmit={formik.handleSubmit}>
        <FormSection verticalPadding={20} topPadding={40}>
          <FormRow>
            <Dropdown
              label="Authorisation types"
              placeholder="Authorisation types"
              options={authorisationTypes}
              onChange={(selectedOption) => {
                formik.setFieldValue('type', selectedOption?.value);
              }}
              value={typeValue}
              width={100}
              widthUnit="%"
            />
          </FormRow>
          <FormRow>
            <Input
              name="referenceNumber"
              placeholder="Reference number"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.referenceNumber}
              width={100}
              widthUnit="%"
            />
          </FormRow>
        </FormSection>
      </FormContainer>
    </Modal>
  );
};
