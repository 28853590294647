import trashIcon from '../../../assets/icons/trash-icon.svg';
import { Button, Input } from '../../../components';
import { FormRow } from '../../../styles/common';
import * as Style from './transit-details-transport-equipment.style';

interface TransitDetailsTransportEquipmentProps {
  form: any;
  disabled: boolean;
}

export const TransitDetailsTransportEquipment: React.FC<TransitDetailsTransportEquipmentProps> = ({
  form,
  disabled,
}) => {
  const addRow = () => {
    const transportEq = form.values.Consignment.TransportEquipment || [];
    form.setFieldValue('Consignment.TransportEquipment', [...transportEq, {}]);
  };

  const removeRow = (rowIdx: number) => {
    const transportEq = form.values.Consignment.TransportEquipment || [];
    transportEq.splice(rowIdx, 1);
    form.setFieldValue('Consignment.TransportEquipment', [...transportEq]);
  };

  const addSeal = (rowIdx: number) => {
    const seal = form.values.Consignment.TransportEquipment[rowIdx].Seal || [];
    form.setFieldValue(`Consignment.TransportEquipment[${rowIdx}].Seal`, [...seal, {}]);
  };

  const removeSeal = (rowIdx: number, sealIdx: number) => {
    const seal = form.values.Consignment.TransportEquipment[rowIdx].Seal || [];
    seal.splice(sealIdx, 1);
    form.setFieldValue(`Consignment.TransportEquipment[${rowIdx}].Seal`, [...seal]);
  };

  const renderFormRow = (rowIdx, transportEq) => {
    const containerPath = (equipmentIdx) =>
      `Consignment.TransportEquipment[${equipmentIdx}].containerIdentificationNumber`;
    const sealPath = (equipmentIdx, sealIdx) =>
      `Consignment.TransportEquipment[${equipmentIdx}].Seal[${sealIdx}].identifier`;

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '360px',
          marginBottom: '24px',
        }}
        key={`${(form.values.Consignment.TransportEquipment || []).length}-${rowIdx}`}
      >
        <FormRow style={{ marginBottom: '10px' }}>
          <Input
            placeholder={`Container ${rowIdx + 1}`}
            onChange={(evt) => form.setFieldValue(containerPath(rowIdx), evt.target.value)}
            name={containerPath(rowIdx)}
            value={form.getFieldProps(containerPath(rowIdx)).value}
            width={100}
            widthUnit="%"
            defaultBehaviour
            disabled={disabled}
          />

          {!disabled && (
            <Style.DeleteButton
              onClick={(event) => {
                event.preventDefault();
                removeRow(rowIdx);
              }}
              style={{ cursor: 'pointer', marginLeft: '16px', marginTop: '5px' }}
            >
              <img src={trashIcon} alt="Remove Row" />
            </Style.DeleteButton>
          )}
        </FormRow>

        {transportEq.Seal?.map((_, sealIdx) => (
          <div style={{ marginBottom: '10px', display: 'flex' }} key={`${transportEq.Seal.length}-${sealIdx}`}>
            <Input
              placeholder={`Seal ${sealIdx + 1}`}
              onChange={(evt) => form.setFieldValue(sealPath(rowIdx, sealIdx), evt.target.value)}
              name={sealPath(rowIdx, sealIdx)}
              value={form.getFieldProps(sealPath(rowIdx, sealIdx)).value}
              width={100}
              widthUnit="%"
              disabled={disabled}
            />

            {!disabled && (
              <Style.DeleteButton
                onClick={(event) => {
                  event.preventDefault();
                  removeSeal(rowIdx, sealIdx);
                }}
              >
                <img
                  src={trashIcon}
                  alt="Remove Seal"
                  style={{
                    cursor: 'pointer',
                    marginTop: '5px',
                  }}
                />
              </Style.DeleteButton>
            )}
          </div>
        ))}

        {!disabled && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Style.Item>
              <Button
                outline
                onClick={(event) => {
                  event.preventDefault();
                  addSeal(rowIdx);
                }}
              >
                Add Seal
              </Button>
            </Style.Item>
          </div>
        )}

        {rowIdx < form.values.Consignment.TransportEquipment.length - 1 && <Style.Separator />}
      </div>
    );
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {form.values.Consignment.TransportEquipment.map((transportEq, rowIdx) => renderFormRow(rowIdx, transportEq))}

      {!disabled && (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Style.Item>
            <Button
              outline
              onClick={(event) => {
                event.preventDefault();
                addRow();
              }}
            >
              Add Container
            </Button>
          </Style.Item>
        </div>
      )}
    </div>
  );
};

export default TransitDetailsTransportEquipment;
