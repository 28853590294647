/* eslint-disable complexity */
/* eslint-disable max-statements */
/* eslint-disable no-use-before-define */
import React, { InputHTMLAttributes } from 'react';
import { Input } from '../';

import { Switcher } from '../switcher/switcher.component';
import * as Style from './input-header.style';

interface InputHeaderProps {
  placeholder?: string;
  headerPlaceholder?: string;
  type?: React.HTMLInputTypeAttribute;
  value?: string | number;
  onChange?: InputHTMLAttributes<HTMLInputElement>['onChange'];
  onBlur?: any;
  onClick?: any;
  name?: string; // yes
  id?: string;
  width?: number;
  /**
   * widthUnit can be "px" or "%"
   */
  widthUnit?: 'px' | '%' | 'em' | 'rem';
  disabled?: boolean;
  defaultBehaviour?: boolean;
  style?: { backgroundColor: string };
  compact?: boolean;

  // switcher props
  onHeaderChange: (value: boolean) => void;
  headerValue?: boolean;
  headerDisabled?: boolean;
}

export const InputHeader: React.FC<InputHeaderProps> = (props) => {
  return (
    <Style.Container width={props.width} widthUnit={props.widthUnit} style={props.style ? props.style : {}}>
      <Style.SwitcherWrapper>
        <Switcher
          size="sm"
          active={props.headerValue}
          onChange={props.onHeaderChange}
          disabled={props.headerDisabled}
          label={props.headerPlaceholder || 'Header'}
        />
      </Style.SwitcherWrapper>
      <Input
        id={props.id}
        type={props.type}
        name={props.name}
        placeholder={props.placeholder}
        onChange={props.onChange}
        onBlur={props.onBlur}
        onClick={props.onClick}
        value={props.value}
        defaultBehaviour={props.defaultBehaviour}
        width={100}
        widthUnit="%"
        disabled={props.disabled}
        compact={props.compact}
      />
    </Style.Container>
  );
};
